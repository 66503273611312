import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'dateToDaysNameConvertion',
})
export class DateToDaysNameConvertionPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): unknown {
    const date = DateTime.fromISO(value);
    const timeBetweenTwoDates = date.startOf('day').diffNow('days').days;
    if (timeBetweenTwoDates < -1) {
      return DateTime.now().plus({ days: timeBetweenTwoDates }).toRelative();
    } else if (timeBetweenTwoDates > -1 && timeBetweenTwoDates < -0) {
      return this.translate.instant('today');
    } else if (timeBetweenTwoDates >= 0 && timeBetweenTwoDates < 1) {
      return this.translate.instant('tomorrow');
    } else {
      return null;
    }
  }
}
