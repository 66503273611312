import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataResource } from '@shared/interfaces/data-resource';
import { MediaPostRequest, MediaFilterParams, MediaPutRequest, MediaResource } from '@data/media/media.model';
import { Create, Delete, Id, Read, Update } from '@core/http/crud-model';
import { PagedResource } from '@shared/interfaces/paged-resource';
import { ReadById } from '@core/http/crud-model/read-by-id';
import { FilterParams } from '@data/filtering/filtering.interface';

const ENDPOINT = '/media';
@Injectable({
  providedIn: 'root',
})
export class MediaService
  implements
    Create<MediaPostRequest, MediaResource>,
    Read<FilterParams, MediaResource>,
    ReadById<MediaResource>,
    Update<MediaPutRequest, MediaResource>,
    Delete<null> {
  constructor(private httpClient: HttpClient) {}

  get(params: FilterParams): Observable<PagedResource<MediaResource>> {
    return this.httpClient.get<PagedResource<MediaResource>>(ENDPOINT, {
      params,
    });
  }

  getById(id: Id): Observable<DataResource<MediaResource>> {
    return this.httpClient.get<DataResource<MediaResource>>(`${ENDPOINT}/${id}`);
  }

  create(params: MediaPostRequest): Observable<DataResource<MediaResource>> {
    const formData = new FormData();
    formData.append('file', params.file);
    if (params.description) {
      formData.append('description', params.description);
    }
    if (params.assign_to_all) {
      formData.append('assign_to_all', params.assign_to_all);
    }
    return this.httpClient.post<DataResource<MediaResource>>(ENDPOINT, formData);
  }

  update(id: Id, params: MediaPutRequest): Observable<DataResource<MediaResource>> {
    return this.httpClient.put<DataResource<MediaResource>>(`${ENDPOINT}/${id}`, params);
  }

  delete(id: Id, params?: null): Observable<DataResource<null>> {
    return this.httpClient.delete<DataResource<null>>(`${ENDPOINT}/${id}`, {
      params,
    });
  }

  download(id: number): Observable<string> {
    return this.httpClient.get(`${ENDPOINT}/${id.toString()}/download`, {
      responseType: 'text',
    });
  }

  downloadFromFullUrl(url: string): Observable<string> {
    return this.httpClient.get(url, {
      responseType: 'text',
    });
  }
}
