import { FormGroup } from '@angular/forms';
import { Component, ContentChild, HostBinding, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { LazyTabDirective } from '../lazy-tab.directive';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'wchfs-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent {
  tabVisibile = false;
  firstBodyInit = true;
  _contentPortal: TemplatePortal | null = null;

  @Input() label: string;
  @Input() code: string;
  @Input() disabled = false;
  @Input() notificationSource: FormGroup;
  @ViewChild(TemplateRef, { static: true }) _implicitContent: TemplateRef<any>;
  @ContentChild(LazyTabDirective, { read: TemplateRef, static: true }) lazyTabBody: any;

  @HostBinding('style.width') get groupWidth() {
    if (this.tabVisibile) {
      return '100%';
    }
  }
  constructor(private _viewContainerRef: ViewContainerRef) {}

  changeTabVisibility(state: boolean): void {
    this.tabVisibile = state;
    if (this.tabVisibile && this.firstBodyInit) {
      this.firstBodyInit = false;
      this._contentPortal = new TemplatePortal(this.lazyTabBody || this._implicitContent, this._viewContainerRef);
    }
  }
}
