<wchfs-form-field>
  <wchfs-label>{{ label }} <span *ngIf="isRequired()">*</span></wchfs-label>
  <ng-container *ngIf="data$ | async as data; else loadingControlBlock">
    <app-base-single-select
      [width]="width"
      [formControl]="control"
      [data]="data"
      [placeholder]="placeholder"
      [config]="config"
      *ngIf="!isMultiselect"
    ></app-base-single-select>
    <app-base-multi-select
      [width]="width"
      [formControl]="control"
      [data]="data"
      [placeholder]="placeholder"
      [config]="config"
      *ngIf="isMultiselect"
    ></app-base-multi-select>
  </ng-container>
  <ng-template #loadingControlBlock>
    <div class="loading-control-block"></div>
  </ng-template>
</wchfs-form-field>
