import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProfileResource } from '@app/@data/profile/profile-resource.interface';

@Component({
  selector: 'app-user-initials',
  templateUrl: './user-initials.component.html',
  styleUrls: ['./user-initials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInitialsComponent {
  @Input() profile: ProfileResource;

  getInitials(): string {
    const firstName = this.profile?.first_name;
    const lastName = this.profile?.last_name;

    return firstName && lastName
      ? `${firstName.charAt(0) + lastName.charAt(0)}`
      : firstName
      ? firstName.charAt(0)
      : null;
  }
}
