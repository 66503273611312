import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ListData } from '@shared/interfaces/list-data';

@Component({
  selector: 'app-table-loader-or-empty-state',
  templateUrl: './table-loader-or-empty-state.component.html',
  styleUrls: ['./table-loader-or-empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableLoaderOrEmptyStateComponent {
  @Input() listData: ListData<unknown>;
  @Input() height = 400;
}
