import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { merge, Subject, Subscription } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { TabItemComponent } from './tab-item/tab-item.component';

@Component({
  selector: 'app-tab-panel',
  templateUrl: './tab-panel.component.html',
  styleUrls: ['./tab-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabPanelComponent implements AfterContentInit, OnDestroy {
  @HostBinding('class.wchfs-tab-panel-fullwidth') @Input() fullwidth = false;
  @ContentChildren(TabItemComponent) tabs: QueryList<TabItemComponent>;
  @Output() activeChange = new EventEmitter<TabItemComponent>();
  @Input() controlByRouter = false;
  routerSubscription: Subscription;
  private onDestroy$ = new Subject<void>();

  constructor(private changeDetectorRef: ChangeDetectorRef, private router: Router) {}

  ngAfterContentInit(): void {
    if (!this.controlByRouter) {
      const activeTabs = this.tabs.filter((tab) => tab.active);
      if (activeTabs.length === 0) {
        this.tabClicked(this.tabs.first);
      } else {
        const [active] = activeTabs;
        this.tabClicked(active);
      }
    } else {
      this.routerSubscription = this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          this.setActiveTab(event.url);
        });
    }
    this.initActiveListener();
  }

  tabClicked(tab: TabItemComponent): void {
    this.tabs.toArray().forEach((item) => (item.active = false));
    tab.active = true;
    this.activeChange.emit(tab);
  }

  initActiveListener(): void {
    merge(...this.tabs.map((tab) => tab.activeChange))
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => this.changeDetectorRef.detectChanges())
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.routerSubscription?.unsubscribe();
  }

  calculateBoldShift(length: number): number {
    return length * 0.05;
  }

  setActiveTab(url: string): void {
    this.tabs.toArray().map((t) => {
      if (url.includes(t.routerLink as string)) {
        this.tabClicked(t);
      }
    });
  }
}
