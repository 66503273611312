<ng-template #suggestionsTplRef>
  <section #mainContentDiv class="ta-results list-group" *ngIf="showSuggestions">
    <div class="ta-backdrop" (click)="hideSuggestions()"></div>
    <div class="no-results" *ngIf="results?.length === 0">{{ 'no-entries' | translate }}</div>
    <button
      type="button"
      class="ta-item list-group-item"
      *ngFor="let result of results; let i = index"
      [class.active]="markIsActive(i, result)"
      (click)="handleSelectionClick(result, i)"
    >
      <span *ngIf="!taItemTpl"><i class="fa fa-search"></i> {{ result }}</span>
      <ng-template
        [ngTemplateOutlet]="taItemTpl"
        [ngTemplateOutletContext]="{
          $implicit: { result: result, index: i }
        }"
      ></ng-template>
    </button>
  </section>
</ng-template>
