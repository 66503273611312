import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DispositionsService } from '@data/dispositions/dispositions.service';
import { FilterParams, PsoFilter } from '@data/filtering/filtering.interface';
import { SelectData } from '@pso-ui/select/select.interfaces';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  template: '',
})
export class DispositionSelectBaseComponent implements PsoFilter<SelectData<unknown>[]>, OnInit {
  @Input() control: FormControl;
  @Input() label = 'dispositions';
  @Input() set params(value: Partial<FilterParams>) {
    this._params = { ...this._params, ...value };

    if (!this.control?.disabled) {
      this.data$ = this.getData(this.params);
    } else {
      this.data$ = of([]);
    }
  }

  get params(): Partial<FilterParams> {
    return this._params;
  }

  data$: Observable<SelectData<unknown>[]>;

  private _params: Partial<FilterParams> = { paginate: '0' };

  constructor(private dispositionsService: DispositionsService) {}

  ngOnInit(): void {
    if (!this.control?.disabled) {
      this.data$ = this.getData(this.params);
    } else {
      this.data$ = of([]);
    }
  }

  getData(params?: Partial<FilterParams>): Observable<SelectData<unknown>[]> {
    return this.dispositionsService.get(params).pipe(
      map((data) => {
        return data.data.map((d) => ({ value: d.id, label: `${d.number} (${d.id})` }));
      })
    );
  }
}
