import { Component, Input } from '@angular/core';
import { StatusResource } from '@app/@data/status/status.interfaces';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
  @Input() status: StatusResource;
}
