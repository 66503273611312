import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivityProperties } from '@data/activities/activities.model';
import { ActivityProperty } from '@pso-ui/vehicle-history-tab/vehicle-history-tab-properties/vehicle-history-tab-properties.model';

@Component({
  selector: 'app-vehicle-history-tab-properties',
  templateUrl: './vehicle-history-tab-properties.component.html',
  styleUrls: ['./vehicle-history-tab-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleHistoryTabPropertiesComponent {
  get properties(): ActivityProperties {
    return this._properties;
  }
  @Input()
  set properties(value: ActivityProperties) {
    this._properties = value;
    this.setActivityProperties(value);
  }

  private _properties: ActivityProperties;

  activityProperties: ActivityProperty<string | number>[];
  static IGNORED: string[] = ['updated_at'];

  private setActivityProperties(value: ActivityProperties) {
    const activityProperties: ActivityProperty<string | number>[] = [];
    Object.keys(value.attributes).forEach((key) => {
      if (value.old && value.old[key] && !VehicleHistoryTabPropertiesComponent.IGNORED.includes(key)) {
        activityProperties.push(
          new ActivityProperty<string | number>(value.old[key].key, value.old[key].value, value.attributes[key].value)
        );
      }
    });
    this.activityProperties = activityProperties;
  }
}
