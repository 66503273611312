import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';

export const UNCHECKED = 'unchecked';
export const CHECKED = 'checked';
export const READONLY = 'readonly';
export const TIME = '0.14s';

export const basicSwitcherAnimation = [
  trigger('toggleSwitcherBasic', [
    state(
      CHECKED,
      style({
        backgroundColor: '{{color}}',
      }),
      {
        params: {
          color: getComputedStyle(document.documentElement).getPropertyValue('--primary-1'),
        },
      }
    ),
    state(
      UNCHECKED,
      style({
        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--grey-5'),
      })
    ),
    transition('void => *', [animate(1)]),
    transition('* => *', [group([query('@switchBasic', animateChild(), { optional: true }), animate(TIME)])]),
    state(
      READONLY,
      style({
        backgroundColor: 'transparent',
      })
    ),
    transition('void => *', [animate(TIME)]),
    transition('* => *', [group([query('@switchBasic', animateChild(), { optional: true }), animate(TIME)])]),
  ]),
  trigger('switchBasic', [
    state(
      UNCHECKED,
      style({
        transform: 'translateX(0)',
      })
    ),
    state(
      CHECKED,
      style({
        transform: 'translateX(16px)',
      })
    ),
    transition('void => *', [animate(1)]),
    transition('* => *', [animate(TIME)]),
  ]),
];
