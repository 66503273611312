import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'coutingTimeFromDate',
})
export class CoutingTimeFromDate implements PipeTransform {
  transform(value: string): unknown {
    const date = DateTime.fromISO(value);
    const today = DateTime.now();
    const timeBetweenNowAndFirstDate = today.diff(date);
    const hours = Math.floor(timeBetweenNowAndFirstDate.as('hours'));
    const minutes = Math.floor(timeBetweenNowAndFirstDate.as('minutes')) % 60;

    if (date > today) {
      return '-';
    }

    let duration;

    if (hours >= 1) {
      duration = `${hours}h`;
    } else {
      duration = `${minutes}m`;
    }
    return duration;
  }
}
