<div
  [@toggleSwitcherBasic]="{
    value: value === config.checkedValue ? CHECKED : UNCHECKED,
    params: { color: color }
  }"
  [style.opacity]="disabled && !readonly ? 0.3 : 1"
  class="app-switcher-type-basic"
>
  <wchfs-icon
    [svgIcon]="value === config.checkedValue ? 'wchfs:tick-big' : 'wchfs:cross-big'"
    [style.width.px]="20"
    [style.height.px]="20"
    [style.position]="'relative'"
    [style.top.px]="value ? -3 : 0"
    [style.visibility]="readonly ? 'visible' : 'hidden'"
  ></wchfs-icon>

  <wchfs-icon
    [style.visibility]="readonly ? 'hidden' : 'visible'"
    class="left"
    color="white"
    svgIcon="wchfs:switcher-tick"
  ></wchfs-icon>
  <wchfs-icon
    [style.visibility]="readonly ? 'hidden' : 'visible'"
    class="right"
    color="white"
    svgIcon="wchfs:switcher-chip-cross"
  ></wchfs-icon>
  <div
    [@switchBasic]="value === config.checkedValue ? CHECKED : UNCHECKED"
    [style.visibility]="readonly ? 'hidden' : 'visible'"
    class="ball"
  ></div>
</div>

<div
  class="main-label"
  #mainLabel
  [style.fontWeight]="readonly ? 700 : null"
  [style.left.px]="readonly ? null : 1"
  [style.top.px]="1"
  [style.opacity]="disabled && !readonly ? 0.3 : 1"
  [title]="mainLabelContent"
>
  <ng-content></ng-content>
</div>
