<ng-container *ngIf="status; else emptyState">
  <ng-container [ngSwitch]="status.value">
    <wchfs-icon
      *ngSwitchCase="TyreAdditionalState.RESERVE"
      [wchfsTooltip]="status.label"
      color="var(--signal-success-green)"
      svgIcon="pso:reserve_m"
      size="24"
    ></wchfs-icon>
    <wchfs-icon
      *ngSwitchCase="TyreAdditionalState.RELOCATION"
      [wchfsTooltip]="status.label"
      svgIcon="pso:relocation_m"
      size="24"
    ></wchfs-icon>
    <wchfs-icon
      *ngSwitchCase="TyreAdditionalState.PENDING_RELEASE"
      [wchfsTooltip]="status.label"
      svgIcon="pso:pending_release_m"
      size="24"
    ></wchfs-icon>
    <ng-container *ngSwitchDefault>-</ng-container>
  </ng-container>
</ng-container>
<ng-template #emptyState> - </ng-template>
