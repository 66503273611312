import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../navigation.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'wchfs-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss'],
})
export class NavListComponent implements OnInit {
  isExpandMenu$: Observable<boolean>;

  constructor(private readonly navigationService: NavigationService) {}

  ngOnInit(): void {
    this.isExpandMenu$ = this.navigationService.isExpandMenu$;
  }
}
