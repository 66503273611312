import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Noise, NoiseClass, NoiseLevel } from '@data/tyres/tyres.model';

@Component({
  selector: 'app-tyre-noise-level',
  templateUrl: './tyre-noise-level.component.html',
  styleUrls: ['./tyre-noise-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TyreNoiseLevelComponent {
  @Input() noise: Noise;
  @Input() noiseLevel: NoiseLevel;
  NoiseClass = NoiseClass;
}
