import { Pipe, PipeTransform } from '@angular/core';
import { TyreStateValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { TyreAdditionalStateValue, TyreStateResource } from '@data/tyres/tyres.model';
import { Store } from '@ngxs/store';
import { TyresState } from '@data/tyres/tyres-state';

@Pipe({
  name: 'toTyreStateResource',
})
export class ToTyreStateResourcePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(
    value: string | undefined,
    ...args: unknown[]
  ): TyreStateResource<TyreStateValue | TyreAdditionalStateValue> | undefined {
    if (!value) {
      return value as undefined;
    }
    const _value = value as TyreStateValue;
    return this.store.selectSnapshot(TyresState.tyreStatus(_value));
  }
}
