import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAnchorTarget]',
})
export class AnchorTargetDirective {
  @Input() appAnchorTarget: HTMLElement;

  @HostListener('click') scrollTo(): void {
    if (this.appAnchorTarget) {
      this.appAnchorTarget.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
