import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TyreResource } from '@data/tyres/tyres.model';
import { Id } from '@core/http/crud-model';

@Component({
  selector: 'app-tyre-season',
  templateUrl: './tyre-season.component.html',
  styleUrls: ['./tyre-season.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TyreSeasonComponent {
  @Input() tyre: TyreResource;
  @Input() id: Id;
}
