import { Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'wchfs-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true,
    },
  ],
})
export class FileInputComponent {
  @Input() accept = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
  @Output() filesUploaded = new EventEmitter<File[]>();
  @Output() fileDropped = new EventEmitter<File[]>();

  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent): void {
    if (this.fileControl.enabled) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = true;
    }
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent): void {
    if (this.fileControl.enabled) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = false;
    }
  }

  @HostListener('drop', ['$event']) onDrop(evt: DragEvent): void {
    if (this.fileControl.enabled) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = false;

      const files = Array.from(evt.dataTransfer?.files || []);
      this.fileDropped.emit(files);
      this.filesUploaded.emit(files);
    }
  }
  fileOver: boolean;
  fileControl = new UntypedFormControl(null);
  uploadFile(event: Event): void {
    if (this.fileControl.enabled) {
      const inputElement = event.target as HTMLInputElement;
      const files: File[] = Array.from(inputElement?.files || []);
      this.filesUploaded.emit(files);

      inputElement.value = '';
    }
  }

  writeValue(files: File[]): void {
    this.fileControl.setValue(files);
  }

  registerOnChange(fn: (files: File[]) => void): void {
    this.fileControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.fileControl.disable();
    } else {
      this.fileControl.enable();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: () => void = () => {};
}
