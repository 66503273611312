import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderStatus, OrderStatusValue } from '@data/orders/orders.model';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderStatusComponent {
  @Input() status: OrderStatusValue;
  OrderStatus = OrderStatus;
}
