import { Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: '[skipNavigationOnSelection]',
})
export class SkipNavigationOnSelectionDirective implements OnDestroy {
  private clickUnListener: () => void;
  private copyUnListener: () => void;

  constructor(private ref: ElementRef, private renderer: Renderer2) {
    this.clickUnListener = renderer.listen(ref.nativeElement as HTMLElement, 'click', (event: MouseEvent) => {
      if (this.isSelectingText()) {
        event.stopImmediatePropagation();
      }
    });

    this.copyUnListener = renderer.listen(ref.nativeElement as HTMLElement, 'copy', (event: ClipboardEvent) => {
      const selectedText = window.getSelection()?.toString();
      if (selectedText) {
        this.copyText(selectedText);
        this.clearSelection();
      }
    });
  }

  ngOnDestroy(): void {
    this.clickUnListener();
    this.copyUnListener();
  }

  private isSelectingText(): boolean {
    return window.getSelection()?.toString().length > 0;
  }

  private copyText(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  private clearSelection(): void {
    if (window.getSelection) {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
    }
  }
}
