import { Component, Input, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { PsoColor } from '@app/@wchfs-ui/lib/colors/colors.model';

export interface ButtonDropdown {
  label: string;
  method: () => void;
}

@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss'],
})
export class ButtonDropdownComponent {
  @Input() actions: ButtonDropdown[] = [];
  @Input() label = 'additional-operations';
  @Input() disabled = false;
  @Input() size: 's' | 'l' = 'l';
  @Input() color: PsoColor = 'primary-gradient';
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
}
