import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { basicSwitcherAnimation, CHECKED, UNCHECKED } from './switcher.animations';
import { DEFAULT_CONFIG, SwitcherComponentConfig } from '@app/@wchfs-ui/lib/form/switcher/switcher.model';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  animations: [basicSwitcherAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitcherComponent),
      multi: true,
    },
  ],
})
export class SwitcherComponent implements ControlValueAccessor {
  @ViewChild('mainLabel') mainLabel: ElementRef;
  @HostListener('click', ['$event'])
  public toggleChecked(): void {
    if (!this.disabled) {
      this.writeValue(this.value === this.config.checkedValue ? this.config.uncheckedValue : this.config.checkedValue);
    }
  }

  @Input() set config(value: Partial<SwitcherComponentConfig>) {
    this._config = {
      ...this._config,
      ...value,
    };
  }

  get config(): SwitcherComponentConfig {
    return this._config;
  }

  value: unknown;
  color = 'var(--primary)';
  readonly = false;
  CHECKED = CHECKED;
  UNCHECKED = UNCHECKED;
  disabled = false;
  mainLabelContent: string;
  private _config: SwitcherComponentConfig = DEFAULT_CONFIG;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  writeValue(value: unknown): void {
    this.value = this.getValue(value);
    this.onChangeFn(this.value);
    this.changeDetectorRef.markForCheck();
  }

  onChangeFn = (_: unknown): void => {
    //
  };
  onTouchedFn = (_: unknown): void => {
    //
  };

  registerOnChange(fn: () => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  private getValue(value: unknown) {
    return value === this.config.checkedValue ? this.config.checkedValue : this.config.uncheckedValue;
  }
}
