import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TyrePosition, TyrePositionSlug } from '@data/tyres/tyres.model';

@Component({
  template: '',
})
export class ActiveTireInteractiveSvgComponent {
  @Input() currentPosition: TyrePositionSlug;
  @HostBinding('class.readonly')
  @Input()
  readonly = false;
  @Output() positionChanged = new EventEmitter<TyrePositionSlug>();
  TyrePosition = TyrePosition;

  onSwitchTyreClick(position: TyrePositionSlug): void | undefined {
    if (this.readonly) {
      return;
    }
    this.currentPosition = position;
    this.positionChanged.emit(position);
  }
}
