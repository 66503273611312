<wchfs-form-field>
  <wchfs-label>{{ 'date-of-receipt' | translate }}</wchfs-label>
  <!--  <input-->
  <!--    [dataCy]="'tire-release-driver-section-datepicker'"-->
  <!--    dateFormat="yyyy-MM-dd"-->
  <!--    [formControl]="control"-->
  <!--    wchfsFilterDate-->
  <!--    wchfsInput-->
  <!--    autocomplete="off"-->
  <!--  />-->

  <input type="text" readonly disabled [value]="DateTime.fromJSDate(control.value).toISODate()" />

  <wchfs-datepicker-toggle [for]="picker"></wchfs-datepicker-toggle>
  <wchfs-calendar
    #picker
    [firstDayOfWeek]=""
    [formControl]="control"
    [value]="control.value"
    [min]="min"
  ></wchfs-calendar>
</wchfs-form-field>
