import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface BreadcrumbChunk {
  displayName: string;
  route?: string;
  translate?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DynamicBreadcrumbsService {
  private readonly _breadcrumbs$ = new BehaviorSubject<BreadcrumbChunk[] | string[]>([]);
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router, private translateService: TranslateService) {
    this.setInitailState();
    this.listenOnNavigationEnd();
  }

  getChunks(chunks: BreadcrumbChunk[] | string[]) {
    if (chunks) {
      this.setBreadcrumb(chunks, chunks);
      this._breadcrumbs$.next(chunks);
    }
  }

  private setInitailState(): void {
    const root = this.router.routerState.snapshot.root;
    const breadcrumbs: BreadcrumbChunk[] = [];
    this.addBreadcrumb(root, breadcrumbs);
    this._breadcrumbs$.next(breadcrumbs);
  }

  private listenOnNavigationEnd(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const root = this.router.routerState.snapshot.root;
      const breadcrumbs: BreadcrumbChunk[] = [];
      this.addBreadcrumb(root, breadcrumbs);
      this._breadcrumbs$.next(breadcrumbs);
    });
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot, breadcrumbs: BreadcrumbChunk[]) {
    if (route) {
      if (route?.data?.breadcrumb) {
        if (!route.data.breadcrumb?.skip) {
          this.setBreadcrumb(route.data, breadcrumbs);
        }
      }
      this.addBreadcrumb(route.firstChild, breadcrumbs);
    }
  }

  private setBreadcrumb(data: any, breadcrumbs: any[]): void {
    if (data.breadcrumb) {
      data.breadcrumb.forEach((breadcrumb: (d: any) => void | string) => {
        let temp;
        if (typeof breadcrumb === 'function') {
          temp = breadcrumb(data);
        } else {
          this.translateBreadcrumb(breadcrumb);
          temp = breadcrumb;
        }
        breadcrumbs.push(temp);
      });
    } else {
      data.forEach((breadcrumb: (d: any) => void | string) => {
        let temp;
        if (typeof breadcrumb === 'function') {
          temp = data;
        } else {
          this.translateBreadcrumb(breadcrumb);
          temp = breadcrumb;
        }
      });
    }
  }

  private translateBreadcrumb(breadcrumb: BreadcrumbChunk): void {
    if (breadcrumb.translate) {
      breadcrumb.displayName = this.translateService.instant(breadcrumb.displayName) as string;
    }
  }
}
