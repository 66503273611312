import { Component, Input } from '@angular/core';
import { VehicleResource } from '@data/vehicles/vehicles.model';

@Component({
  selector: 'app-fleet-plate',
  templateUrl: './fleet-plate.component.html',
  styleUrls: ['./fleet-plate.component.scss'],
})
export class FleetPlateComponent {
  @Input() VIPBadge = false;
  @Input() vehicle: VehicleResource;
  @Input() fleetName: string;
}
