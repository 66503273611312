import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _requestArray = new BehaviorSubject<string[]>([]);

  getReguestArray(): Observable<string[]> {
    return this._requestArray.asObservable();
  }
  getReguestArrayValue(): string[] {
    return this._requestArray.value;
  }
  setRequestArray(value: string[]): void {
    this._requestArray.next(value);
  }
}
