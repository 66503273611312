<div class="filters">
  <div class="filters-content" [style.display]="hide ? 'none' : ''">
    <div class="grid-wrapper">
      <ng-content select=".grid-content"></ng-content>
    </div>
    <ng-content select=".outside-content"></ng-content>
  </div>

  <div class="buttons-wrapper">
    <div></div>

    <div class="buttons-right">
      <button
        [dataCy]="'filter-clear-btn'"
        pso-outline-button
        [style.visibility]="hide ? 'hidden' : 'inherit'"
        type="button"
        (click)="clearFilters()"
      >
        {{ 'clear' | translate | uppercase }}
      </button>

      <button
        [dataCy]="'filter-submit-btn'"
        pso-button
        [color]="'primary-gradient'"
        [disabled]="!searchFormQuery.valid"
        [style.visibility]="hide ? 'hidden' : 'inherit'"
        (click)="onFormSubmit()"
        type="submit"
      >
        {{ 'generate-report' | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
