import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDnd]',
})
export class DndDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter();
  @Output() dragEvent = new EventEmitter();

  @HostListener('dragover', ['$event']) onDragOver(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragEvent.emit(event);
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragEvent.emit(event);
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public ondrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
    this.fileOver = false;
  }
}
