<ng-container *ngIf="vehicle">
  <ng-container *ngIf="fleetName">
    <div class="fleet-wrap">
      <app-vip-badge *ngIf="VIPBadge"></app-vip-badge>
      <div class="fleet-name" [wchfsTooltip]="fleetName">
        {{ fleetName }}
      </div>
    </div>
  </ng-container>
  <div class="plate">{{ vehicle?.registration_number }}</div>
  <div class="vin" *ngIf="vehicle?.vin">{{ vehicle.vin }}</div>
</ng-container>

<ng-container *ngIf="!vehicle">
  <div class="fleet-wrap">
    <div class="fleet-name">-</div>
  </div>
  <div class="plate">-</div>
  <div class="vin">-</div>
</ng-container>
