import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { DispositionAuthResource } from '@data/disposition-auth/disposition-auth.model';

@Component({
  selector: 'app-authorization-handlling-cell-role',
  templateUrl: './authorization-handlling-cell-role.component.html',
  styleUrls: ['./authorization-handlling-cell-role.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizationHandllingCellRoleComponent implements OnInit {
  @Input() auths: DispositionAuthResource[];
  roles: string[] = [];
  ngOnInit(): void {
    this.displayAuthRoles();
  }

  private displayAuthRoles(): void {
    this.auths
      .filter((auth) => auth.is_auth_required)
      .map((auth) => {
        this.roles.push(auth?.auth_group);
      });
  }
}
