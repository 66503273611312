import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NotePostRequest, NoteResource } from '@data/note/note.model';
import { BehaviorSubject } from 'rxjs';
import { RolesGroupEnum } from '@core/Roles';
import { NoteService } from '@data/note/note.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent implements AfterViewInit {
  @Input() notes: NoteResource[];
  @Input() groups: RolesGroupEnum[];
  @Input() readonly = false;
  @Input() service: NoteService;
  @ViewChild('chatContainer') chatContainer: ElementRef;
  control = new FormControl('');
  loading$ = new BehaviorSubject<boolean>(false);
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  addNote(): void {
    this.loading$.next(true);
    this.service.create(this.prepareRequest()).subscribe((res) => {
      this.notes.push(res?.data);
      this.scrollToBottom();
      this.loading$.next(false);
      this.changeDetectorRef.detectChanges();
    });
    this.control.reset();
  }

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    this.changeDetectorRef.detectChanges();
  }

  private prepareRequest(): NotePostRequest {
    const params = {
      note: this.control.value,
    };

    if (this.groups) {
      Object.assign(params, {
        roles_groups_names: this.groups,
      });
    }

    return params;
  }
}
