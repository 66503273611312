import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-coordinates',
  templateUrl: './coordinates.component.html',
  styleUrls: ['./coordinates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoordinatesComponent {
  @Input() lat: string;
  @Input() lng: string;
}
