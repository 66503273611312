<div class="input-wrap" [class.active]="control.value.length > min">
  <wchfs-form-field>
    <input
      [dataCy]="'create-task-vehicle-search-input'"
      wchfsInput
      type="text"
      [formControl]="control"
      [placeholder]="'requests.registration-number-or-vin-number' | translate"
      (keyup.enter)="control.value.length > 0 ? confirm.emit() : ''"
      [style.height.px]="vehicleSearchInputComponentSize?.getHeight()"
      [style.font-size.px]="vehicleSearchInputComponentSize?.getFontSize()"
      [style.width]="vehicleSearchInputComponentSize?.getWidth()"
      [style.padding-right.px]="vehicleSearchInputComponentSize.getHeight()"
    />
    <div
      [dataCy]="'create-task-vehicle-search-button'"
      class="search-icon-wrap"
      (click)="confirm.emit(); $event.preventDefault()"
      [style.height.px]="vehicleSearchInputComponentSize?.getHeight()"
      [style.width.px]="vehicleSearchInputComponentSize?.getHeight()"
    >
      <wchfs-icon svgIcon="pso-legacy:Button_Search_ico" size="22" wchfsSuffix></wchfs-icon>
    </div>
  </wchfs-form-field>
</div>
