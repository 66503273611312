import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Create, Export, Id, Read, Update } from '@app/@core/http/crud-model';
import { DataResource } from '@app/@shared/interfaces/data-resource';
import { MediaResource } from '@data/media/media.model';
import { PagedResource } from '@shared/interfaces/paged-resource';
import { Observable } from 'rxjs';
import { VehicleResource, VehicleStoreRequest } from './vehicles.model';
import { ReadById } from '@core/http/crud-model/read-by-id';
import { FilterParams } from '@data/filtering/filtering.interface';

const ENDPOINT = '/vehicles';
const DEFAULT_WITH = 'fleet,driver,tyres,fittedTyres,latestDisposition,tyreDefaults,draftTyres,currentTyres';

@Injectable({
  providedIn: 'root',
})
export class VehiclesService
  implements
    Read<FilterParams, VehicleResource>,
    ReadById<VehicleResource>,
    Create<VehicleStoreRequest, VehicleResource>,
    Update<Omit<VehicleStoreRequest, 'id'>, VehicleResource>,
    Export<FilterParams, MediaResource> {
  constructor(private httpClient: HttpClient) {}

  get(params: Partial<FilterParams & { vehicle_keyword: string }>): Observable<PagedResource<VehicleResource>> {
    return this.httpClient.get<PagedResource<VehicleResource>>(`${ENDPOINT}`, {
      params: {
        with: DEFAULT_WITH,
        ...params,
      },
    });
  }

  getById(id: Id): Observable<DataResource<VehicleResource>> {
    return this.httpClient.get<DataResource<VehicleResource>>(`${ENDPOINT}/${id}`, {
      params: {
        with: DEFAULT_WITH,
      },
    });
  }

  update(id: Id, request: Omit<VehicleStoreRequest, 'id'>): Observable<DataResource<VehicleResource>> {
    return this.httpClient.put<DataResource<VehicleResource>>(`${ENDPOINT}/${id}`, request);
  }

  export(params?: FilterParams): Observable<DataResource<MediaResource>> {
    return this.httpClient.get<DataResource<MediaResource>>(`${ENDPOINT}/actions/export`, {
      params: new HttpParams({ fromObject: params }),
    });
  }

  create(params: VehicleStoreRequest): Observable<DataResource<VehicleResource>> {
    return this.httpClient.post<DataResource<VehicleResource>>(`${ENDPOINT}`, params);
  }
}
