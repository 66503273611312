import { Pipe, PipeTransform } from '@angular/core';
import { DispositionAuthGroupName } from '@data/authorization/authorization.model';
import { DispositionAuthResource } from '@data/disposition-auth/disposition-auth.model';

@Pipe({
  name: 'authForGroup',
})
export class AuthForGroupPipe implements PipeTransform {
  transform(value: DispositionAuthResource[], ...args: DispositionAuthGroupName[]): DispositionAuthResource {
    const [group]: DispositionAuthGroupName[] = args;
    return value.find((g) => g.auth_group === group);
  }
}
