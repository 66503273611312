import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Adhesion } from '@data/tyres/tyres.model';

@Component({
  selector: 'app-tyre-adhesion',
  templateUrl: './tyre-adhesion.component.html',
  styleUrls: ['./tyre-adhesion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TyreAdhesionComponent {
  @Input() adhesion: Adhesion;
}
