export type LegacyFilterParams = { [param: string]: string | number | unknown[] };

export class LaravelFilterParams {
  params: LegacyFilterParams;

  constructor(params: LegacyFilterParams) {
    this.params = params;
  }

  getFlatParams(): LegacyFilterParams {
    Object.keys(this.params).forEach((key) => {
      if (Array.isArray(this.params[key])) {
        this.params[key] = (this.params[key] as []).join();
      }
    });
    return this.params;
  }
}
