import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Roles } from '@app/@core/Roles';
import { UserResource } from '@data/users/users.model';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRolesComponent {
  @Input() user: UserResource;
  Roles = Roles;
}
