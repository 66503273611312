import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'daysFromToday',
})
export class DaysFromTodayPipe implements PipeTransform {
  transform(added_at: string): number {
    const today = DateTime.now().startOf('day');
    const addedAt = DateTime.fromISO(added_at).startOf('day');
    let countDays = today.diff(addedAt, ['days']).toObject().days;
    return countDays;
  }
}
