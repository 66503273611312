import { Observable } from 'rxjs';
import { NgControl } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive()
export abstract class FormFieldControlDirective<T> {
  value: T | null;

  readonly stateChanges: Observable<void>;

  readonly id: string;

  readonly placeholder: string;

  readonly ngControl: NgControl | null;

  readonly focused: boolean;

  readonly empty: boolean;

  readonly shouldLabelFloat: boolean;

  readonly required: boolean;

  readonly disabled: boolean;

  abstract onContainerClick(event: MouseEvent): void;
}
