import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FiltersSectionComponent } from '@pso-ui/filters-section/filters-section.component';

@Component({
  selector: 'app-reports-filters-section',
  templateUrl: './reports-filters-section.component.html',
  styleUrls: ['../../../../@pso-ui/filters-section/filters-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsFiltersSectionComponent extends FiltersSectionComponent {}
