<section [@toggleLeft]="(isExpandMenu$ | async) ? 'open' : 'close'" class="navigation-container">
  <div class="navigation-header">
    <ng-content *ngIf="!isHamburger" select="wchfs-nav-header"></ng-content>
    <div
      (click)="toggleLeftSidebar()"
      *ngIf="isHamburger"
      class="navigation-header-hamburger-wrapper"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div class="navigation-header-hamburger" fxLayoutAlign="center center">
        <wchfs-icon size="24" svgIcon="wchfs:hamburger"></wchfs-icon>
      </div>
      <div class="navigation-header-logo" fxLayoutAlign="left right">
        <ng-content select="wchfs-icon"></ng-content>
        <ng-content select="img"></ng-content>
      </div>
    </div>
  </div>

  <div
    [@togglePseudoTop]="(isExpandMenu$ | async) ? 'open' : 'close'"
    [class.closed]="!(isExpandMenu$ | async)"
    class="pseudo-top"
  >
    <ng-content select="wchfs-nav-up-list"></ng-content>
  </div>

  <div class="navigation-content">
    <div [@toggleDate]="(isExpandMenu$ | async) ? 'open' : 'close'" class="date">
      <div *ngIf="isExpandMenu$ | async as isExpandMenu" class="navigation-data" fxLayout="column">
        <span [ngClass]="{ 'navigation-data-weekday-short-form': !isExpandMenu }" class="navigation-data-weekday">{{
          isExpandMenu ? today.format('dddd') : today.format('dddd').slice(0, 3)
        }}</span>
        <span *ngIf="isExpandMenu" class="navigation-data-day">{{ today.format('D MMMM') }}</span>
        <span [ngClass]="{ 'navigation-data-year-short-form': !isExpandMenu }" class="navigation-data-year">{{
          isExpandMenu ? today.format('Y') : today.format('Y').slice(0, 3)
        }}</span>
        <!--        <hr />-->
      </div>
    </div>
    <!--    TODO propably unnecessary-->
    <!--    <ng-container *ngIf="!isExpandMenu">-->
    <!--        <div class="navigation-data" fxLayout="column" fxLayoutAlign="center center">-->
    <!--          <span class="navigation-data-weekday">{{ today.format('dd') }}</span>-->
    <!--          <span class="navigation-data-day"-->
    <!--          >{{ today.format('D') }}-->
    <!--            <span class="navigation-data-day-month">{{ today.format('MMM') }}</span>-->
    <!--            </span>-->
    <!--          <span class="navigation-data-year">{{ today.format('Y') }}</span>-->
    <!--          <hr/>-->
    <!--        </div>-->
    <!--    </ng-container>-->
    <div class="navigation-list">
      <ng-content select="wchfs-nav-content"></ng-content>
    </div>
    <div class="navigation-decrease-button">
      <wchfs-nav-decrease-button *ngIf="!isHamburger"></wchfs-nav-decrease-button>
    </div>
  </div>
</section>
