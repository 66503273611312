import { Id } from '@app/@core/http/crud-model';
import { DateTimeString } from '@app/@shared/interfaces/date-string';
import { RoleResource } from '@data/roles-group-resource/roles-group-resource.model';

export interface ActivitiesResource {
  id: Id;
  description: string;
  action: string;
  event_details: string;
  level: string;
  user: LogUserResource;
  properties: ActivityProperties;
  created_at: DateTimeString;
  updated_at: DateTimeString;
}

export interface ActivityProperties {
  attributes: {
    [key: string]: {
      key: string;
      value: string | number;
    };
  };
  old: {
    [key: string]: {
      key: string;
      value: string | number;
    };
  };
}

export type ActivityTypeSubjectSlug = 'vehicle' | 'tyre';
export class ActivityTypeSubject {
  static SUBJECT_VEHICLE: ActivityTypeSubjectSlug = 'vehicle';
  static SUBJECT_TYRE: ActivityTypeSubjectSlug = 'tyre';
}

export interface LogUserResource {
  name: string;
  roles: RoleResource[];
}
