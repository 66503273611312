<ng-container *ngIf="status; else emptyState">
  <ng-container [ngSwitch]="status.value">
    <wchfs-icon
      *ngSwitchCase="TyreAdditionalState.PAID_DEPOSIT"
      [wchfsTooltip]="status.label"
      svgIcon="pso:deposits_m"
      size="24"
    ></wchfs-icon>
    <wchfs-icon
      *ngSwitchCase="TyreAdditionalState.DEPOSIT_EXTENSION"
      [wchfsTooltip]="status.label"
      svgIcon="pso:buffer_m"
      size="24"
    ></wchfs-icon>
    <wchfs-icon
      *ngSwitchCase="TyreAdditionalState.WAITING"
      [wchfsTooltip]="status.label"
      svgIcon="pso:waiting_m"
      size="24"
    ></wchfs-icon>
    <ng-container *ngSwitchDefault>-</ng-container>
  </ng-container>
</ng-container>
<ng-template #emptyState> - </ng-template>
