import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ListData } from '@shared/interfaces/list-data';
import { PagedResource } from '@shared/interfaces/paged-resource';

@Component({
  selector: 'app-pagination-under-table',
  templateUrl: './pagination-under-table.component.html',
  styleUrls: ['./pagination-under-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationUnderTableComponent {
  @Output() setPage = new EventEmitter();
  @Input() listData: ListData<PagedResource<unknown>>;
}
