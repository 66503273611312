<wchfs-form-field>
  <wchfs-label>{{ label | translate }}</wchfs-label>
  <ng-container *ngIf="data$ | async as data; else loadingControlBlock">
    <app-base-single-select
      *ngIf="!readonly"
      [dataCy]="'vehicle-type-single-select'"
      [formControl]="control"
      [data]="data"
      [placeholder]="'choose' | translate"
      [config]="{
        prefixTemplate: iconRim
      }"
    >
      <ng-template #iconRim>
        <wchfs-icon svgIcon="pso-legacy:car_ico"></wchfs-icon>
      </ng-template>
    </app-base-single-select>
    <app-select-readonly *ngIf="readonly">{{ control.value ? (control.value | translate) : '-' }}</app-select-readonly>
  </ng-container>
  <ng-template #loadingControlBlock>
    <div class="loading-control-block"></div>
  </ng-template>
</wchfs-form-field>
