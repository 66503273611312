import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dot-input',
  templateUrl: './dot-input.component.html',
  styleUrls: ['./dot-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotInputComponent {
  @Input() control: FormControl;
  DOTKeydownValue(event: KeyboardEvent): void {
    if (
      ![
        'ArrowLeft',
        'ArrowUp',
        'ArrowDown',
        'ArrowRight',
        'Backspace',
        'Delete',
        'Tab',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '+',
      ].includes(event.key)
    ) {
      // INFO: only numbers and action keys
      event.preventDefault();
    }
  }
}
