import { Component, Input } from '@angular/core';
import { Step } from '@app/@pso-ui/steps/steps.interface';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent {
  @Input() steps: Step[];

  isNextStep(index: number): boolean {
    const lastActiveIndex = this.findLastActiveIndex();
    return index === lastActiveIndex + 1;
  }

  private findLastActiveIndex(): number {
    for (let i = this.steps.length - 1; i >= 0; i--) {
      if (this.steps[i].active) {
        return i;
      }
    }
    return -1;
  }
}
