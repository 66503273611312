<ng-container [ngSwitch]="position">
  <wchfs-icon *ngSwitchCase="TyrePosition.SPARE" svgIcon="pso:spare_m" color="supporting-3" size="24"></wchfs-icon>
  <wchfs-icon *ngSwitchCase="null" svgIcon="pso:drivetrain_m" color="secondary" size="24"></wchfs-icon>
  <wchfs-icon *ngSwitchDefault svgIcon="pso:drivetrain_m" color="secondary" size="24"></wchfs-icon>
</ng-container>

<ng-container [ngSwitch]="position">
  <span *ngSwitchCase="TyrePosition.SPARE" class="name name--spare">{{
    TyrePosition.translateKey(TyrePosition.SPARE) | translate | uppercase
  }}</span>
  <span *ngSwitchCase="null" class="name name--default">-</span>
  <span *ngSwitchDefault class="name name--default">{{
    TyrePosition.translateKey(position) | translate | uppercase
  }}</span>
</ng-container>
