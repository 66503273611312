import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { StringUnitLength } from 'luxon/src/misc';

@Pipe({
  name: 'hoursBetweenNowAndDate',
})
export class HoursBetweenNowAndDatePipe implements PipeTransform {
  transform(value: string, stringUnitLength: StringUnitLength = 'long'): { value: number; formatted: string } {
    const date = DateTime.fromISO(value);
    const timeBetweenTwoDates = DateTime.now().diff(date, 'hours').hours;
    const formatted = DateTime.now()
      .minus({ hours: timeBetweenTwoDates })
      .toRelative({
        unit: 'hours',
        style: stringUnitLength,
      })
      .replace('za ', '');

    return {
      value: this.getValue(formatted),
      formatted: formatted,
    };
  }

  private getValue(formatted: string): number | undefined {
    const [value] = formatted.split(' ');
    if (value) {
      return +value;
    }
    return;
  }
}
