import { DispositionItemPartTypeValue, Service } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { DispositionItemResource } from '@data/authorization/authorization.model';

export interface ProductData {
  getDescription(): string;
  getPartType(): DispositionItemPartTypeValue;
  getCatalogNumber(): string;
  getProducer(): string;
  hasData(): boolean;
}

class ProductDataBase {
  protected producer: string;
  protected catalogNumber: string;
  protected partType: DispositionItemPartTypeValue;
  protected description: string;
  getDescription(): string {
    return this.description;
  }
  getPartType(): DispositionItemPartTypeValue {
    return this.partType;
  }
  getCatalogNumber(): string {
    return this.catalogNumber;
  }
  getProducer(): string {
    return this.producer;
  }
  hasData(): boolean {
    return !!this.producer || !!this.catalogNumber || !!this.partType || !!this.description;
  }
}

export class ServiceProductData extends ProductDataBase {
  constructor(private data: Service) {
    super();
    this.producer = data?.meta?.producer;
    this.catalogNumber = data?.meta?.catalog_number;
    this.partType = data?.meta?.part_type;
    this.description = data?.meta?.remarks;
  }
}

export class DispositionItemProductData extends ProductDataBase {
  constructor(private data: DispositionItemResource) {
    super();
    this.producer = data?.producer;
    this.catalogNumber = data?.catalog_number;
    this.partType = data?.part_type?.value;
    this.description = data?.description;
  }
}
