import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wchfs-expanded-list-content',
  templateUrl: './expanded-list-content.component.html',
  styleUrls: ['./expanded-list-content.component.scss'],
})
export class ExpandedListContentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
