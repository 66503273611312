import { Component, HostBinding, OnInit } from '@angular/core';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'wchfs-nav-sublist',
  templateUrl: './nav-sublist.component.html',
  styleUrls: ['./nav-sublist.component.scss'],
})
export class NavSublistComponent implements OnInit {
  @HostBinding('class.nav-sublist-hidden') isClose: boolean = true;

  constructor(public navigationService: NavigationService) {}

  ngOnInit(): void {
    this.navigationService.isExpandMenu$.subscribe((isExpandMenu: boolean) => {
      if (!isExpandMenu) {
        this.isClose = true;
      }
    });
  }

  toggle() {
    this.isClose = !this.isClose;

    if (!this.isClose) {
      this.navigationService.setExpandMenu();
    }
  }
}
