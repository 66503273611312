import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NotificationService } from '@app/@shared/services/notification.service';

@Directive({
  selector: '[appCopyToClipboard]',
})
export class CopyToClipboardDirective {
  @Input() copyText: string;
  @HostListener('click', ['$event'])
  copyToClipboard(event: MouseEvent): void {
    const textArea = document.createElement('textarea');
    textArea.value = this.copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.notificationService.notifySuccess('value-copied-to-clipboard');
  }
  constructor(private el: ElementRef, private notificationService: NotificationService) {}
}
