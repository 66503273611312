<ng-container *ngIf="auth?.authorized_at">
  <div *ngIf="auth?.status === 'accepted'">
    <wchfs-icon svgIcon="pso:success_m" size="24"></wchfs-icon>
    <div class="date">{{ time }}</div>
  </div>
  <div *ngIf="auth?.status !== 'accepted'">
    <wchfs-icon
      svgIcon="pso:clock_m"
      size="24"
      class="clock"
      [ngClass]="iconColor !== '' ? iconColor : ''"
    ></wchfs-icon>
    <div class="date">{{ time }}</div>
  </div>
</ng-container>
