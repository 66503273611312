<div class="textarea">
  <div class="label">{{ label }}</div>
  <textarea
    [style.height]="height + 'px'"
    [dataCy]="'textarea-with-limitations'"
    [formControl]="control"
    [placeholder]="placeholder | translate"
    [maxlength]="textareaMaxLength"
    #textarea
  ></textarea>
  <div class="remarks-limit">
    {{ textarea?.value?.length }} / {{ textareaMaxLength }}
    {{ textarea?.value?.length | i18nPlural: messageMapping | translate: { count: textarea?.value?.length } }}
  </div>
</div>
