<div class="search-form-action" fxFlex fxLayout="column" fxLayoutAlign="space-between center">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
    <div (click)="isOpen = !isOpen" class="hide-content" fxLayoutAlign="row">
      <p>{{ isOpen ? labels.hide : labels.open }}</p>
      <wchfs-icon
        [ngClass]="{ 'hide-content-arrow-icon-rotated': isOpen }"
        class="hide-content-arrow-icon"
        size="7"
        svgIcon="wchfs:form_arrow_down"
      ></wchfs-icon>
    </div>
    <div *ngIf="isOpen" fxFlex="grow" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" style="width: 100%">
        <ng-content></ng-content>
      </div>
      <button
        (click)="submitOnClick()"
        *ngIf="showButton"
        class="search-form-submit-button"
        color="supporting-5-light-blue"
        size="s"
        pso-button
      >
        {{ labels.search }}
      </button>
    </div>
  </div>
</div>
