import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Id, Read } from '@app/@core/http/crud-model';
import { DataResource } from '@app/@shared/interfaces/data-resource';
import { PagedResource } from '@app/@shared/interfaces/paged-resource';
import { Observable } from 'rxjs';
import { ActivitiesResource } from './activities.model';
import { FilterParams } from '@data/filtering/filtering.interface';
import { ReadById } from '@core/http/crud-model/read-by-id';

const ENDPOINT = '/activities';
@Injectable({
  providedIn: 'root',
})
export class ActivitiesService implements Read<FilterParams, ActivitiesResource>, ReadById<ActivitiesResource> {
  constructor(private httpClient: HttpClient) {}

  getById(id: Id): Observable<DataResource<ActivitiesResource>> {
    return this.httpClient.get<DataResource<ActivitiesResource>>(`${ENDPOINT}/${id}`);
  }

  get(params: FilterParams): Observable<PagedResource<ActivitiesResource>> {
    return this.httpClient.get<PagedResource<ActivitiesResource>>(`${ENDPOINT}`, {
      params,
    });
  }
}
