<div class="records-info">
  <wchfs-records-info [totalElements]="listData?.response?.meta?.total" *ngIf="listData?.response"></wchfs-records-info>
</div>
<div>
  <wchfs-new-paginator
    (page$)="setPage.emit($event)"
    [meta]="listData?.response?.meta"
    *ngIf="listData?.response"
  ></wchfs-new-paginator>
</div>
