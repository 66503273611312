import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TyreParamResource } from '@data/tyres/tyres.model';

@Component({
  selector: 'app-tyre-params',
  templateUrl: './tyre-params.component.html',
  styleUrls: ['./tyre-params.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TyreParamsComponent {
  @Input() tyreParams: TyreParamResource[];
  @Input() showSeparator = true;
}
