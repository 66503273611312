import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

export interface BreadcrumbChunk {
  displayName: string;
  route?: string;
}
@Component({
  selector: 'wchfs-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  @Input() chunks: BreadcrumbChunk[] | string[] = [];

  constructor(private router: Router) {}

  goToLink(route: string | undefined): void {
    if (route) {
      this.router.navigateByUrl(route);
    }
  }
}
