import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

export enum Key {
  Backspace = 'Backspace',
  Tab = 'Tab',
  Enter = 'Enter',
  ShiftLeft = 'ShiftLeft',
  ShiftRight = 'ShiftRight',
  Escape = 'Escape',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  // http://unixpapa.com/js/key.html
  MetaLeft = 'MetaLeft',
  MetaRight = 'MetaRight',
}

export interface PsoTypeaheadControl<T> {
  control: AbstractControl;
  searchControl: UntypedFormControl;
  search: string;
  valueProp: TypeaheadValueProp;
  call(context: string): Observable<T[]>;
  handleResultSelected(result: T): void;
  clearInput(): void;
}

export type TypeaheadValueProp = 'id' | 'display_name' | undefined;
