import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { price } from '@shared/utils/price';

@Component({
  selector: 'app-margin',
  templateUrl: './margin.component.html',
  styleUrls: ['./margin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarginComponent implements OnInit {
  get sell(): number {
    return this._sell;
  }
  @Input()
  set sell(value: number) {
    this._sell = value;
    this.calculate();
  }
  get buy(): number {
    return this._buy;
  }
  @Input()
  set buy(value: number) {
    this._buy = value;
    this.calculate();
  }
  private _buy: number;
  private _sell: number;
  margin = 0;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.calculate();
  }

  calculate(): void {
    if (this._sell <= 0) {
      this.margin = 0;
      return;
    }
    this.margin = price((this._sell - this._buy) / this._sell);
    this.changeDetectorRef.markForCheck();
  }
}
