<div class="wchfs-range-wrapper">
  <div [class]="{ inverted: inverted }" class="wchfs-range">
    <div
      (click)="onClick(item)"
      *ngFor="let item of options"
      [class]="{
        active: item.active,
        extreme: item.extreme,
        initial: item.initial,
        final: item.final
      }"
      class="item"
    >
      <div class="bar"></div>
      <div class="label">{{ item.label }}</div>
    </div>
  </div>
</div>

<ul style="font-size: 12px; display: none">
  <li>current state: {{ state }}</li>
  <li>
    selectedOptions:
    <pre>{{ selectedOptions | json }}</pre>
  </li>
</ul>
