import { Component, Input, OnInit } from '@angular/core';

// TODO remove

@Component({
  selector: 'wchfs-nav-logo',
  templateUrl: './nav-logo.component.html',
  styleUrls: ['./nav-logo.component.scss'],
})
export class NavLogoComponent implements OnInit {
  @Input() path: string;
  @Input() size = 'x-large';
  isPng = false;

  ngOnInit(): void {
    this.isPng = this.path.includes('png');
  }
}
