<ng-content *ngIf="loading === false; else loader"></ng-content>

<div
  class="button-ripple"
  [buttonRippleCentered]="wchfsRippleCentered"
  [buttonRippleColor]="rippleColor"
  [buttonRippleDisabled]="false"
  [buttonRippleTrigger]="hostElement"
  buttonRipple
></div>

<ng-template #loader>
  <app-dot-loader></app-dot-loader>
</ng-template>
