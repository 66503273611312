import { DispositionResource } from '../dispositions/dispositions.model';
import { PaginationParams, SortingParams, TabParams } from '../filtering/filtering.interface';
import {
  TyreClassResource,
  TyreLogResource,
  TyreResource,
  TyreSeasonResource,
  TyreTypeResource,
} from '../tyres/tyres.model';
import { TyreSeason } from '@data/tyres/tyres.service';
import { ProductTyreResource } from '../product-tyres/product-tyres.model';
import { DriverResource } from '../driver/driver.model';
import { ProducerResource } from '../producer/producer.model';
import { RimTypeValue, SpareWheelType, TyreState } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { FleetResource } from '@data/fleets/fleets.model';
import { FormControl, FormGroup } from '@angular/forms';
import { InputOptionResource } from '@app/@feed-inputs';
import { DateTimeString } from '@app/@shared/interfaces/date-string';
import { EnumResource } from '@shared/interfaces/enum-resource';

export interface VehicleResource {
  cost_group: any;
  id: number;
  name: string;
  vin: string;
  registration_number: string;
  fleet: FleetResource;
  driver: DriverResource;
  wheels_number: number;
  reserve_wheels_number: number;
  fitted_tyres?: ProductTyreResource[];
  fitted_tyre_logs: TyreLogResource[];
  draft_tyres: TyreResource[];
  draft_tyre_logs: TyreLogResource[];
  current_tyres: TyreResource[];
  current_tyre_logs: TyreLogResource[];
  latest_disposition: DispositionResource;
  draft_disposition: DispositionResource;
  mileage: number;
  status: EnumResource<VehicleStatusValue>;
  tyre_defaults: VehicleTyreDefaultResource[];
  last_activity?: string;
  updated_at: DateTimeString;
  created_at: DateTimeString;
  type: TyreGenericField;
  rim: TyreGenericField;
  spare_type: TyreGenericField;
  has_tpms: boolean;
}

export interface TyreGenericField {
  id: string;
  name: string;
  key: string;
  value: string;
  description: string;
}

export interface VehicleFilterParams extends PaginationParams, SortingParams, TabParams {
  vehicles: InputOptionResource;
  fleets: string;
  vehicle_brands: string;
  drivers: string;
  tyre_season: string;
  status: string;
  with_mobile_app: string;
  cost_groups: string;
  with_closed_dispositions?: 0 | 1;
}

export type VehicleFilterParamsFormGroup = FormGroup<{
  vehicles: FormControl<InputOptionResource>;
  fleets: FormControl<string[]>;
  vehicle_brands: FormControl<string>;
  drivers: FormControl<string>;
  tyre_season: FormControl<string[]>;
  status: FormControl<string>;
  with_mobile_app: FormControl<string>;
  cost_groups: FormControl<string>;
  with_closed_dispositions?: FormControl<0 | 1>;
}>;

export interface VehicleTyreDefaultResource {
  width: string;
  speed_index: string;
  rolling_resistance: string;
  noise_level: string;
  noise: string;
  load_index: string;
  height: string;
  diameter: string;
  producer: ProducerResource;
  producer_id: number;
  tyre_class: TyreClassResource;
  tyre_season: TyreSeasonResource;
  tyre_type: TyreTypeResource;
}

export interface VehicleStoreRequest {
  id: number | string;
  vin: string;
  registration_number: string;
  status: string;
  name: string;
  wheels_number: number;
  reserve_wheels_number: number;
  region: {
    id: string | number;
  };
  vehicle_brand: {
    id: string | number;
  };
  fleet: {
    id: string | number;
  };
  cost_group: {
    id: number | string;
  };
  driver: {
    id: number | string;
  };
  tyre_defaults: any;
  type: string | TyreGenericField;
  rim: string | TyreGenericField;
  spare_type: string | TyreGenericField;
  has_tpms: boolean;
}
export interface VehicleTyreDefaultStoreRequest {
  producer: {
    id: number;
  };
  tyre_season: {
    value: 'summer' | 'winter' | 'yearly';
  };
  tyre_type: {
    value: string;
  };
  load_index?: string;
  noise_level?: number | string;
  tyre_class?: {
    value: string;
  };
  height?: {
    value: string;
  };
  width?: {
    value: string;
  };
  diameter?: {
    value: string;
  };
  speed_index?: {
    value: string;
  };
  rolling_resistance?: {
    value: string;
  };
  adhesion?: {
    value: string;
  };
  noise?: {
    value: string;
  };
}

export class VehicleTyreDefaults {
  order = [TyreSeason.WINTER, TyreSeason.SUMMER, TyreSeason.YEARLY];
  constructor(private vehicleTyreDefaults: VehicleTyreDefaultResource[]) {}

  getSegment(): string {
    let segment: string;
    this.order.forEach((season) => {
      if (!segment) {
        segment = this.vehicleTyreDefaults.find((d) => d.tyre_season.id === season)?.tyre_class?.name;
      }
    });

    return segment || null;
  }

  getProducer(): string {
    let producer: string;
    this.order.forEach((season) => {
      if (!producer) {
        producer = this.vehicleTyreDefaults.find((d) => d.tyre_season.id === season)?.producer?.name;
      }
    });

    return producer || null;
  }
}

export class Vehicle {
  private raw: VehicleResource;
  constructor(private vehicle: VehicleResource) {
    this.raw = vehicle;
  }

  getMileage(): number {
    return this.vehicle?.latest_disposition?.mileage;
  }

  getDriverName(): string {
    return this.vehicle?.driver ? this.vehicle?.driver?.name : this.vehicle?.latest_disposition?.driver_name;
  }

  getDriverPhone(): string {
    return this.vehicle?.driver ? this.vehicle?.driver?.phone_number : this.vehicle?.latest_disposition?.driver_phone;
  }

  getRimType(): RimTypeValue {
    return this.vehicle?.latest_disposition?.rim_type;
  }

  getTpms(): boolean {
    return this.vehicle?.latest_disposition?.tpms;
  }

  getSpareWheel(): SpareWheelType {
    return this.vehicle?.latest_disposition?.spare_wheel;
  }

  getTyresOnCar(): TyreLogResource[] {
    return this.vehicle?.current_tyre_logs?.filter((tl) => tl.tyre_state.value === TyreState.VEHICLE);
  }

  getTyresInDeposit(): TyreLogResource[] {
    return this.vehicle?.current_tyre_logs?.filter((tl) => tl.tyre_state.value === TyreState.DEPOSIT);
  }

  getRawValue(): VehicleResource {
    return this.raw;
  }
}

export type VehicleStatusValue = 'active' | 'inactive';

export class VehicleStatus {
  static ACTIVE: VehicleStatusValue = 'active';
  static INACTIVE: VehicleStatusValue = 'inactive';
}

export interface VehicleBrandResource {
  id: number;
  name: string;
  updated_at: string;
  created_at: string;
}
