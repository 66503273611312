import { Component, HostBinding, Inject } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { SnackBarData } from '../snack-bars.constants';

@Component({
  selector: 'wchfs-actions-snack-bar',
  templateUrl: './actions-snack-bar.component.html',
  styleUrls: ['./actions-snack-bar.component.scss'],
})
export class ActionsSnackBarComponent {
  actionButtons = this.data.actions;
  snackbarIconName = 'wchfs:snackbar-' + this.data.type;

  @HostBinding('class') class = this.data.type;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData,
    private _snackRef: MatSnackBarRef<ActionsSnackBarComponent>
  ) {}

  dismiss() {
    this._snackRef.dismiss();
  }

  actionHandler(handler: () => void) {
    handler();
    this._snackRef.dismiss();
  }
}
