import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DRAG_AND_DROP_COMPONENT_DEFAULT_CONFIG,
  DragAndDropComponentConfig,
  FileMimeTypes,
} from './drag-and-drop.model';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
})
export class DragAndDropComponent implements OnInit {
  @Output() filesUploaded = new EventEmitter<FileList>();
  extensionsInfo: string = this.generateExtensionsInfo(DRAG_AND_DROP_COMPONENT_DEFAULT_CONFIG);
  acceptedExtensions: FileMimeTypes[] = [];
  messageMapping = {
    text: {
      '=1': 'drag-and-drop.header-1',
      other: 'drag-and-drop.header-few',
    },
    acceptedFormat: {
      '=1': 'drag-and-drop.accepted-format',
      other: 'drag-and-drop.accepted-formats',
    },
  };
  private _config: DragAndDropComponentConfig = DRAG_AND_DROP_COMPONENT_DEFAULT_CONFIG;

  @Input() set config(value: Partial<DragAndDropComponentConfig>) {
    this._config = {
      ...DRAG_AND_DROP_COMPONENT_DEFAULT_CONFIG,
      ...value,
    };
    this.extensionsInfo = this.generateExtensionsInfo(this._config);
    this.updateAcceptedExtensions();
  }

  get config(): DragAndDropComponentConfig {
    return this._config;
  }

  ngOnInit(): void {
    this.updateAcceptedExtensions();
  }

  onFileDropped(files: FileList): void {
    if (files) {
      this.prepareFilesList(files);
    }
  }

  fileBrowseHandler(files: FileList): void {
    if (files) {
      this.prepareFilesList(files);
    }
  }

  updateAcceptedExtensions(): void {
    this.acceptedExtensions = this.config.accept.map((res) => res.extension);
  }

  private prepareFilesList(files: FileList) {
    this.filesUploaded.emit(files);
  }

  private generateExtensionsInfo(config: DragAndDropComponentConfig): string {
    return config.accept.map((t) => t.extension.replace('.', '')).join(', ');
  }
}
