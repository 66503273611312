import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DetailsData } from '@app/@shared/interfaces/details-data';

@Component({
  selector: 'app-details-loader-state',
  templateUrl: './details-loader-state.component.html',
  styleUrls: ['./details-loader-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsLoaderStateComponent {
  @Input() detailsData: DetailsData<unknown>;
  @Input() height = 400;
}
