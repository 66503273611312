import { CompletionType, CompletionTypeSlug } from '@data/tyres/tyres.model';
import { RimType, RimTypeValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';

export interface FullWheelLabel {
  formattedLabel: string;
  formattedAdditionalInfo: string;
  map: Map<CompletionTypeSlug, string>;
  completionType: CompletionTypeSlug;
  rimType: RimTypeValue;
  generateFormattedInfo(): string;
  getFormattedLabel(): string;
  getFormattedAdditionalInfo(): string;
  completeWheel(): string;
  tyreOnly(): string;
  rimOnly(): string;
  empty(): string;
}

export class FullWheelLabelBase {
  formattedLabel: string;
  formattedAdditionalInfo: string;
  map = new Map<CompletionTypeSlug, string>([
    [CompletionType.COMPLETE_WHEEL, `completeWheel`],
    [CompletionType.TYRE_ONLY, `tyreOnly`],
    [CompletionType.RIM_ONLY, `rimOnly`],
    [null, `empty`],
  ]);

  constructor(
    public completionType: CompletionTypeSlug,
    public rimType: RimTypeValue,
    public completionTypeTranslate?: string,
    public rimTypeTranslate?: string
  ) {
    this.formattedLabel = this[this.map.get(this.completionType)]();
    this.formattedAdditionalInfo = this.generateFormattedInfo();
  }

  generateFormattedInfo(): string {
    if (this.rimType) {
      return `${this.completionTypeTranslate || this.completionType || 'brak'} | ${
        this.rimTypeTranslate || this.rimType || 'brak'
      }`;
    }
    return `${this.completionTypeTranslate || this.completionType || 'null'}`;
  }

  getFormattedLabel(): string {
    return this.formattedLabel;
  }

  getFormattedAdditionalInfo(): string {
    return this.formattedAdditionalInfo;
  }
}

export class DepositFullWheelLabel extends FullWheelLabelBase implements FullWheelLabel {
  completeWheel(): string {
    switch (this.rimType) {
      case RimType.ALUMINIUM: {
        return 'Tak (Alu)';
      }
      case RimType.STEEL: {
        return 'Tak (Stal)';
      }
      default: {
        return '-';
      }
    }
  }

  tyreOnly(): string {
    return 'Nie';
  }

  rimOnly(): string {
    return 'Nie';
  }

  empty(): string {
    return '-';
  }
}

export class VehicleFullWheelLabel extends FullWheelLabelBase implements FullWheelLabel {
  completeWheel(): string {
    switch (this.rimType) {
      case RimType.ALUMINIUM: {
        return 'Alu';
      }
      case RimType.STEEL: {
        return 'Stal';
      }
      default: {
        return '-';
      }
    }
  }

  tyreOnly(): string {
    switch (this.rimType) {
      case RimType.ALUMINIUM: {
        return 'Alu';
      }
      case RimType.STEEL: {
        return 'Stal';
      }
      default: {
        return '-';
      }
    }
  }

  rimOnly(): string {
    return '-';
  }

  empty(): string {
    return '-';
  }
}

export type FullWheelLabelContextValue = keyof typeof FullWheelLabelContext;
export class FullWheelLabelContext {
  static DEPOSIT: FullWheelLabelContextValue = 'DEPOSIT';
  static VEHICLE: FullWheelLabelContextValue = 'VEHICLE';
}
