import { Component, Input } from '@angular/core';
import { MediaResource } from '@data/media/media.model';
import { MediaService } from '@data/media/media.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class PrintComponent {
  @Input() media: MediaResource;

  constructor(private mediaService: MediaService) {}

  download(apiUrl: string): void {
    this.mediaService.downloadFromFullUrl(apiUrl).subscribe((urlToMedia: string) => {
      window.open(urlToMedia);
    });
  }
}
