<!--<app-button-secondary-arrow-right [arrowDown]="t.menuOpen" mat-button [matMenuTriggerFor]="menu" #t="matMenuTrigger">-->
<!--</app-button-secondary-arrow-right>-->

<button
  pso-button
  [icon]="t.menuOpen ? 'pso:arrow_up_m' : 'pso:arrow_down_m'"
  [matMenuTriggerFor]="menu"
  #t="matMenuTrigger"
  [color]="color"
  [size]="size"
  [disabled]="disabled"
  type="button"
>
  {{ label | translate | uppercase }}
</button>

<mat-menu #menu="matMenu" class="customize">
  <button
    mat-menu-item
    *ngFor="let action of actions; let i = index"
    (click)="action.method(action.actionKey)"
    class="dropdown-option"
    [dataCy]="'dropdown-button-' + i"
    type="button"
  >
    <span>{{ action.label | translate }}</span>
  </button>
</mat-menu>
