import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductTyreResource } from '@app/@data/product-tyres/product-tyres.model';
import { TyreParam, TyreParamType, TyreResource } from '@app/@data/tyres/tyres.model';

@Component({
  selector: 'app-tyre-info',
  templateUrl: './tyre-info.component.html',
  styleUrls: ['./tyre-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TyreInfoComponent {
  @Input() tyre: TyreResource;
  @Input() productTyre: ProductTyreResource;
  TyreParam = TyreParam;
  TyreParamType = TyreParamType;
}
