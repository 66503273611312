import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class DateAndTimeService {
  constructor(private translate: TranslateService) {}

  dateToDaysCovertion(_date: string): { date: string; isDaysAgoVisible: boolean } {
    const date = DateTime.fromISO(_date);
    const timeBetweenTwoDates = date.diffNow('days').days;
    if (date.diffNow('days').days > -4 && date.diffNow('days').days < -1) {
      return { date: DateTime.now().plus({ days: timeBetweenTwoDates }).toRelative(), isDaysAgoVisible: true };
    } else if (date.diffNow('days').days > -1 && date.diffNow('days').days <= 0) {
      return { date: this.translate.instant('today'), isDaysAgoVisible: true };
    }
  }

  dateToTimeConvertion(_date: any) {
    const date = DateTime.fromISO(_date);
    const timeBetweenTwoDates = date.diffNow('days').days;
    return DateTime.now().plus({ days: timeBetweenTwoDates }).toRelative();
  }

  displayTimeAgo(timeDiff: number) {
    return DateTime.now().plus({ days: timeDiff }).toRelative();
  }
  differenceBetweenDates(dateFrom: string, dateTo: string): number {
    const date1 = DateTime.fromISO(dateFrom);
    const date2 = DateTime.fromISO(dateTo);
    return date2.diff(date1, 'days').days;
  }
}
