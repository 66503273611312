import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { GenericModal, GenericModalConfig, ModalType, ModalTypeName, PSOButtons } from '../modal.model';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericModalComponent<T> implements OnInit, GenericModal<T> {
  type: ModalTypeName;
  PSOButtons = PSOButtons;
  config: GenericModalConfig;
  defaultConfig: GenericModalConfig = {
    type: ModalType.INFO,
    title: '',
    text: '',
    buttons: [],
  };
  constructor(
    public dialogRef: MatDialogRef<GenericModalComponent<T>>,
    @Inject(MAT_DIALOG_DATA)
    public data: GenericModalConfig
  ) {}

  ngOnInit(): void {
    this.config = {
      ...this.defaultConfig,
      ...this.data,
    };
    this.type = this.config.type;
  }

  close(): void {
    this.dialogRef.close();
  }
  confirm(action: T): void {
    this.dialogRef.close(action);
  }
}
