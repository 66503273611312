import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TabComponent } from '../tab/tab.component';

export interface TabLabel {
  name: string;
  disabled: boolean;
}

export interface SingleTab {
  index: number;
  label: TabLabel;
  code?: string;
  flag: boolean;
  initialTabFormValue?: {};
  notification: boolean;
  notificationErrors: ValidationErrors[];
  form: FormGroup;
}

@Component({
  selector: 'wchfs-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
})
export class TabGroupComponent implements AfterContentInit, OnDestroy {
  tabs: SingleTab[] = [];
  @Input() activeTabIndex = 0;
  @Input() refresher: Subject<boolean>;
  @Output() onTabChange = new EventEmitter();
  @Output() onFormValueChange = new EventEmitter<SingleTab[]>();

  @ContentChildren(TabComponent) tabList: QueryList<TabComponent>;

  ngAfterContentInit(): void {
    this.setTabData();
    this.changeTab(this.activeTabIndex);
    this.listenForTabNotifications();
    this.listenForFormSave();
  }

  changeTab(index: number): void {
    if (this.tabList.length > 0) {
      this.tabList['_results'].forEach((tab: TabComponent) => {
        tab.changeTabVisibility(false);
      });
      this.activeTabIndex = index;
      this.tabList['_results'][this.activeTabIndex].changeTabVisibility(true);
      this.onTabChange.emit(index);
    }
  }

  private listenForFormSave(): void {
    if (!this.refresher) {
      return;
    }
    this.refresher.pipe(untilDestroyed(this)).subscribe(() => {
      this.tabs.forEach((tab) => {
        if (tab.notification) {
          tab.notification = false;
          tab.initialTabFormValue = tab.form.value;
        }
      });
    });
  }

  private listenForTabNotifications(): void {
    if (this.tabList.length > 0) {
      this.tabList['_results'].forEach((tab: TabComponent, idx: number) => {
        tab.notificationSource?.valueChanges.pipe(untilDestroyed(this), debounceTime(150)).subscribe((changes) => {
          this.updateNotification(tab, changes, idx);
          this.setInitialFormTabValue(idx, changes);
          this.onFormValueChange.emit(this.tabs);
        });
      });
    }
  }

  private setInitialFormTabValue(index: number, changes: {}): void {
    if (!this.tabs[index].flag) {
      this.tabs[index].initialTabFormValue = changes;
      this.tabs[index].flag = true;
    }
  }

  private updateNotification(tab: TabComponent, changes: {}, index: number): void {
    this.tabs[index].notification = this.compareFormValue(changes, index);
    this.tabs[index].notificationErrors = this.getFormGroupErrors(tab.notificationSource);
  }

  private compareFormValue(changes: {}, index: number): boolean {
    if (this.tabs[index]?.initialTabFormValue) {
      const initialValue = this.tabs[index].initialTabFormValue;
      return JSON.stringify(initialValue) !== JSON.stringify(changes);
    }
  }

  private getFormGroupErrors(formGroup: FormGroup): ValidationErrors[] {
    if (!formGroup) {
      return;
    }
    // @ts-ignore
    const controlErrorsArray: ValidationErrors[] = this.getFormErrors(formGroup);
    return controlErrorsArray;
  }

  private setTabData(): void {
    this.tabList['_results'].forEach((tab: TabComponent, idx: number) => {
      const t: SingleTab = {
        index: idx,
        label: { name: tab.label, disabled: tab.disabled },
        notification: false,
        flag: false,
        form: tab.notificationSource,
        code: tab.code,
        notificationErrors: this.getFormGroupErrors(tab.notificationSource),
      };
      this.tabs.push(t);
    });
  }

  private getFormErrors(form: AbstractControl) {
    if (form instanceof UntypedFormControl) {
      return form.errors ?? null;
    }
    if (form instanceof FormGroup || form instanceof FormArray) {
      const formErrors: any[] = [];
      Object.keys(form.controls).forEach((key) => {
        // @ts-ignore
        const error: any[] = this.getFormErrors(form.get(key));
        if (error !== null) {
          formErrors.push(error);
        }
      });
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }
  ngOnDestroy(): void {}
}
