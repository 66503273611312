import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { LegacyFilterParams, LaravelFilterParams } from '@shared/utils/laravel-filter-params';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { price } from '@shared/utils/price';
import { NotificationService } from '@shared/services/notification.service';

@Component({
  selector: 'app-filters-section',
  templateUrl: './filters-section.component.html',
  styleUrls: ['./filters-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersSectionComponent {
  @Input() searchFormQuery: UntypedFormGroup;
  @Output() submitEvent = new EventEmitter();
  @Output() resetEvent = new EventEmitter();

  hide = false;
  customErrors = {
    minlength: this.translateService.instant('input-errors.minlength', { value: 3 }),
  };

  constructor(
    private translateService: TranslateService,
    protected cdr: ChangeDetectorRef,
    protected notificationService: NotificationService
  ) {}

  getControl(name: string): UntypedFormControl {
    return this.searchFormQuery.get(name) as UntypedFormControl;
  }

  onHideButtonClick(): void {
    this.hide = !this.hide;
  }

  onFormSubmit(): void {
    const filteredAndMultipliedValues: LegacyFilterParams = {};
    Object.keys(this.searchFormQuery.controls).forEach((controlName) => {
      // Check if the control name includes "price"
      if (controlName.includes('price') && this.searchFormQuery.get(controlName).value) {
        filteredAndMultipliedValues[controlName] = price(this.searchFormQuery.get(controlName).value);
      } else {
        filteredAndMultipliedValues[controlName] = this.searchFormQuery.get(controlName).value;
      }
    });

    const laravelFilterParams: LegacyFilterParams = new LaravelFilterParams(
      filteredAndMultipliedValues
    ).getFlatParams();
    this.submitEvent.emit(laravelFilterParams);
  }

  clearFilters(): void {
    this.searchFormQuery.reset();
    this.resetEvent.emit();
  }
}
