import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Id } from '@core/http/crud-model';

@Component({
  selector: 'app-collective-checkbox',
  templateUrl: './collective-checkbox.component.html',
  styleUrls: ['./collective-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectiveCheckboxComponent implements OnInit, OnDestroy {
  @Input() selection: SelectionModel<Id>;
  @Input() collectiveCheckbox: FormControl;
  @Input() data: Required<{ id: Id }>[];
  private selectionChangeSubscription: Subscription;
  private collectiveCheckboxValueChangeSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.listenCollectiveCheckboxChanges();
  }

  ngOnDestroy(): void {
    this.selectionChangeSubscription?.unsubscribe();
    this.collectiveCheckboxValueChangeSubscription?.unsubscribe();
  }

  private listenCollectiveCheckboxChanges() {
    let listeningIsOn = true;

    this.selectionChangeSubscription = this.selection.changed
      .pipe(filter(() => listeningIsOn))
      .subscribe((selectionChange: SelectionChange<Id>) => {
        if (selectionChange.removed.length > 0) {
          listeningIsOn = false;
          this.collectiveCheckbox.patchValue(false, {
            emitEvent: false, // INFO: it's not working so listeningIsOn helper
          });
          listeningIsOn = true;
        }

        if (selectionChange.added.length > 0) {
          if (this.selection.selected.length === this.data.length) {
            listeningIsOn = false;
            this.collectiveCheckbox.patchValue(true, {
              emitEvent: false, // INFO: it's not working so listeningIsOn helper
            });
            listeningIsOn = true;
          }
        }
        this.cdr.detectChanges();
      });

    this.collectiveCheckboxValueChangeSubscription = this.collectiveCheckbox.valueChanges
      .pipe(filter(() => listeningIsOn))
      .subscribe((checked: boolean) => {
        if (checked) {
          this.data.forEach((item: Required<{ id: Id }>) => {
            this.selection.select(item.id);
          });
        } else {
          listeningIsOn = false;
          this.selection.clear();
          listeningIsOn = true;
        }
        this.cdr.detectChanges();
      });
  }
}
