export interface DragAndDropComponentConfig {
  multiple: boolean;
  accept: FileMimeType[];
  width: string;
}

export class FileMimeTypes {
  public static ALL: FileMimeType = {
    type: '*',
    extension: '.all',
  };
  public static AAC: FileMimeType = {
    type: 'audio/aac',
    extension: '.aac',
  };
  public static ABW: FileMimeType = {
    type: 'application/x-abiword',
    extension: '.abw',
  };
  public static ARC: FileMimeType = {
    type: 'application/x-freearc',
    extension: '.arc',
  };
  public static AVI: FileMimeType = {
    type: 'video/x-msvideo',
    extension: '.avi',
  };
  public static AZW: FileMimeType = {
    type: 'application/vnd.amazon.ebook',
    extension: '.azw',
  };
  public static BIN: FileMimeType = {
    type: 'application/octet-stream',
    extension: '.bin',
  };
  public static BMP: FileMimeType = {
    type: 'image/bmp',
    extension: '.bmp',
  };
  public static BZ: FileMimeType = {
    type: 'application/x-bzip',
    extension: '.bz',
  };
  public static BZ2: FileMimeType = {
    type: 'application/x-bzip2',
    extension: '.bz2',
  };
  public static CSH: FileMimeType = {
    type: 'application/x-csh',
    extension: '.csh',
  };
  public static CSS: FileMimeType = {
    type: 'text/css',
    extension: '.css',
  };
  public static CSV: FileMimeType = {
    type: 'text/csv',
    extension: '.csv',
  };
  public static DOC: FileMimeType = {
    type: 'application/msword',
    extension: '.doc',
  };
  public static DOCX: FileMimeType = {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extension: '.docx',
  };
  public static EOT: FileMimeType = {
    type: 'application/vnd.ms-fontobject',
    extension: '.eot',
  };
  public static EPUB: FileMimeType = {
    type: 'application/epub+zip',
    extension: '.epub',
  };
  public static GZ: FileMimeType = {
    type: 'application/gzip',
    extension: '.gz',
  };
  public static GIF: FileMimeType = {
    type: 'image/gif',
    extension: '.gif',
  };
  public static HTM: FileMimeType = {
    type: 'text/html',
    extension: '.htm',
  };
  public static HTML: FileMimeType = {
    type: 'text/html',
    extension: '.html',
  };
  public static ICO: FileMimeType = {
    type: 'image/vnd.microsoft.icon',
    extension: '.ico',
  };
  public static ICS: FileMimeType = {
    type: 'text/calendar',
    extension: '.ics',
  };
  public static JAR: FileMimeType = {
    type: 'application/java-archive',
    extension: '.jar',
  };
  public static JPEG: FileMimeType = {
    type: '.jpg',
    extension: '.jpeg',
  };
  public static JS: FileMimeType = {
    type: 'text/javascript',
    extension: '.js',
  };
  public static JSON: FileMimeType = {
    type: 'application/json',
    extension: '.json',
  };
  public static JSONLD: FileMimeType = {
    type: 'application/ld+json',
    extension: '.jsonld',
  };
  public static MID: FileMimeType = {
    type: '.midi',
    extension: '.mid',
  };
  public static MJS: FileMimeType = {
    type: 'text/javascript',
    extension: '.mjs',
  };
  public static MP3: FileMimeType = {
    type: 'audio/mpeg',
    extension: '.mp3',
  };
  public static MPEG: FileMimeType = {
    type: 'video/mpeg',
    extension: '.mpeg',
  };
  public static MPKG: FileMimeType = {
    type: 'application/vnd.apple.installer+xml',
    extension: '.mpkg',
  };
  public static ODP: FileMimeType = {
    type: 'application/vnd.oasis.opendocument.presentation',
    extension: '.odp',
  };
  public static ODS: FileMimeType = {
    type: 'application/vnd.oasis.opendocument.spreadsheet',
    extension: '.ods',
  };
  public static ODT: FileMimeType = {
    type: 'application/vnd.oasis.opendocument.text',
    extension: '.odt',
  };
  public static OGA: FileMimeType = {
    type: 'audio/ogg',
    extension: '.oga',
  };
  public static OGV: FileMimeType = {
    type: 'video/ogg',
    extension: '.ogv',
  };
  public static OGX: FileMimeType = {
    type: 'application/ogg',
    extension: '.ogx',
  };
  public static OPUS: FileMimeType = {
    type: 'audio/opus',
    extension: '.opus',
  };
  public static OTF: FileMimeType = {
    type: 'font/otf',
    extension: '.otf',
  };
  public static PNG: FileMimeType = {
    type: 'image/png',
    extension: '.png',
  };
  public static PDF: FileMimeType = {
    type: 'application/pdf',
    extension: '.pdf',
  };
  public static PHP: FileMimeType = {
    type: 'application/php',
    extension: '.php',
  };
  public static PPT: FileMimeType = {
    type: 'application/vnd.ms-powerpoint',
    extension: '.ppt',
  };
  public static PPTX: FileMimeType = {
    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    extension: '.pptx',
  };
  public static RAR: FileMimeType = {
    type: 'application/vnd.rar',
    extension: '.rar',
  };
  public static RTF: FileMimeType = {
    type: 'application/rtf',
    extension: '.rtf',
  };
  public static SH: FileMimeType = {
    type: 'application/x-sh',
    extension: '.sh',
  };
  public static SVG: FileMimeType = {
    type: 'image/svg+xml',
    extension: '.svg',
  };
  public static SWF: FileMimeType = {
    type: 'application/x-shockwave-flash',
    extension: '.swf',
  };
  public static TAR: FileMimeType = {
    type: 'application/x-tar',
    extension: '.tar',
  };
  public static TIF: FileMimeType = {
    type: 'image/tiff',
    extension: '.tif',
  };
  public static TIFF: FileMimeType = {
    type: 'image/tiff',
    extension: '.tiff',
  };
  public static TS: FileMimeType = {
    type: 'video/mp2t',
    extension: '.ts',
  };
  public static TTF: FileMimeType = {
    type: 'font/ttf',
    extension: '.ttf',
  };
  public static TXT: FileMimeType = {
    type: 'text/plain',
    extension: '.txt',
  };
  public static VSD: FileMimeType = {
    type: 'application/vnd.visio',
    extension: '.vsd',
  };
  public static WAV: FileMimeType = {
    type: 'audio/wav',
    extension: '.wav',
  };
  public static WEBA: FileMimeType = {
    type: 'audio/webm',
    extension: '.weba',
  };
  public static WEBM: FileMimeType = {
    type: 'video/webm',
    extension: '.webm',
  };
  public static WEBP: FileMimeType = {
    type: 'image/webp',
    extension: '.webp',
  };
  public static WOFF: FileMimeType = {
    type: 'font/woff',
    extension: '.woff',
  };
  public static WOFF2: FileMimeType = {
    type: 'font/woff2',
    extension: '.woff2',
  };
  public static XHTML: FileMimeType = {
    type: 'application/xhtml+xml',
    extension: '.xhtml',
  };
  public static XLS: FileMimeType = {
    type: 'application/vnd.ms-excel',
    extension: '.xls',
  };
  public static XLSX: FileMimeType = {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: '.xlsx',
  };
  public static XML: FileMimeType = {
    type: 'XML',
    extension: '.xml',
  };
  public static XUL: FileMimeType = {
    type: 'application/vnd.mozilla.xul+xml',
    extension: '.xul',
  };
  public static ZIP: FileMimeType = {
    type: 'application/zip',
    extension: '.zip',
  };
  public static THREE_GP: FileMimeType = {
    type: 'video/3gpp',
    extension: '.three_gp',
  };
  public static THREE_G2: FileMimeType = {
    type: 'video/3gpp2',
    extension: '.three_g2',
  };
  public static SEVEN_Z: FileMimeType = {
    type: 'application/x-7z-compressed',
    extension: '.seven_z',
  };

  public static excelTypes(): MimeType[] {
    return [this.XLS.type, this.XLSX.type];
  }

  public static excelExtensions(): string[] {
    return [this.XLS.extension, this.XLSX.extension];
  }
}

export type MimeType =
  | '*'
  | 'audio/aac'
  | 'application/x-abiword'
  | 'application/x-freearc'
  | 'video/x-msvideo'
  | 'application/vnd.amazon.ebook'
  | 'application/octet-stream'
  | 'image/bmp'
  | 'application/x-bzip'
  | 'application/x-bzip2'
  | 'application/x-csh'
  | 'text/css'
  | 'text/csv'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/vnd.ms-fontobject'
  | 'application/epub+zip'
  | 'application/gzip'
  | 'image/gif'
  | 'text/html'
  | 'text/html'
  | 'image/vnd.microsoft.icon'
  | 'text/calendar'
  | 'application/java-archive'
  | '.jpg'
  | 'text/javascript'
  | 'application/json'
  | 'application/ld+json'
  | '.midi'
  | 'text/javascript'
  | 'audio/mpeg'
  | 'video/mpeg'
  | 'application/vnd.apple.installer+xml'
  | 'application/vnd.oasis.opendocument.presentation'
  | 'application/vnd.oasis.opendocument.spreadsheet'
  | 'application/vnd.oasis.opendocument.text'
  | 'audio/ogg'
  | 'video/ogg'
  | 'application/ogg'
  | 'audio/opus'
  | 'font/otf'
  | 'image/png'
  | 'application/pdf'
  | 'application/php'
  | 'application/vnd.ms-powerpoint'
  | 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  | 'application/vnd.rar'
  | 'application/rtf'
  | 'application/x-sh'
  | 'image/svg+xml'
  | 'application/x-shockwave-flash'
  | 'application/x-tar'
  | 'image/tiff'
  | 'image/tiff'
  | 'video/mp2t'
  | 'font/ttf'
  | 'text/plain'
  | 'application/vnd.visio'
  | 'audio/wav'
  | 'audio/webm'
  | 'video/webm'
  | 'image/webp'
  | 'font/woff'
  | 'font/woff2'
  | 'application/xhtml+xml'
  | 'application/vnd.ms-excel'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'XML'
  | 'application/vnd.mozilla.xul+xml'
  | 'application/zip'
  | 'video/3gpp'
  | 'video/3gpp2'
  | 'application/x-7z-compressed';

export interface FileMimeType {
  type: MimeType;
  extension: string;
}

export const DRAG_AND_DROP_COMPONENT_DEFAULT_CONFIG: DragAndDropComponentConfig = {
  multiple: false,
  accept: [FileMimeTypes.ALL],
  width: '362px',
};
