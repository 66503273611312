import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { EMPTY, Observable, Subject, Subscription, of } from 'rxjs';
import { concat, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { Key } from './pso-typeahead.model';
import {
  NO_INDEX,
  hasCharacters,
  isEnterKey,
  isEscapeKey,
  isIndexActive,
  resolveApiMethod,
  resolveItemValue,
  resolveNextIndex,
  toFormControlValue,
  toJsonpFinalResults,
  toJsonpSingleResult,
  validateArrowKeys,
  validateNonCharKeyCode,
} from './pso-typeahead.utils';

/*
 using an external template:
 <input [taItemTpl]="itemTpl" >

  <ng-template #itemTpl let-result>
    <strong>MY {{ result.result }}</strong>
  </ng-template>
*/
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'pso-typeahead, [psoTypeahead]',
  templateUrl: './pso-typeahead.component.html',
  styleUrls: ['./pso-typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsoTypeAheadComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mainContentDiv')
  mainContentDiv: ElementRef;
  @ViewChild(TemplateRef, { static: true })
  suggestionsTplRef: TemplateRef<any>;

  @Input() minLength = 4;
  @Input()
  taItemTpl!: TemplateRef<any>;
  @Input()
  taUrl = '';
  @Input()
  taParams = {};
  @Input()
  taQueryParam = 'q';
  @Input()
  taCallbackParamValue: string;
  @Input()
  taApi = 'jsonp';
  @Input()
  taApiMethod = 'get';
  @Input()
  taList: any[] = [];
  @Input()
  taListItemField: any = [];
  @Input()
  taListItemLabel = '';
  @Input()
  taDebounce = 300;
  @Input()
  taAllowEmpty = true;
  @Input()
  taCaseSensitive = false;
  @Input()
  taDisplayOnFocus = false;
  @Input()
  call: (query: string) => Observable<unknown[]>;
  @Output()
  taSelected = new EventEmitter<unknown>();

  @HostListener('keydown', ['$event'])
  handleEsc(event: KeyboardEvent) {
    if (isEscapeKey(event)) {
      this.hideSuggestions();
      event.preventDefault();
    }
    this.keydown$.next(event);
  }
  @HostListener('keyup', ['$event'])
  onkeyup(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.keyup$.next(event);
  }
  @HostListener('click')
  onClick() {
    if (this.taDisplayOnFocus) {
      this.displaySuggestions();
    }
  }

  showSuggestions = false;
  results: string[] = [];
  private isFocus = false;
  private suggestionIndex = 0;
  private activeResult = '';
  private searchQuery = '';
  private resultsAsItems: any[] = [];
  private keydown$ = new Subject<KeyboardEvent>();
  private keyup$ = new Subject<KeyboardEvent>();
  private scrollContainerValue = 0;
  private _subscription: Subscription = new Subscription();

  constructor(
    private element: ElementRef,
    private viewContainer: ViewContainerRef,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private focusMonitor: FocusMonitor
  ) {}

  ngOnInit(): void {
    this.filterEnterEvent(this.keydown$);
    this.listenAndSuggest(this.keyup$);
    this.navigateWithArrows(this.keydown$);
    this.renderTemplate();
  }

  ngAfterViewInit(): void {
    this._subscription.add(
      this.focusMonitor.monitor(this.element.nativeElement, true).subscribe((value: FocusOrigin) => {
        this.isFocus = true;

        if (value === null) {
          this.keyup$.next();
        }

        if (value === 'mouse') {
          this.resetResults();
        }

        if (value === null && this.resultsAsItems?.length === 0) {
          this.isFocus = false;
          this.resetResults();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.keydown$.complete();
    this.keyup$.complete();
    this._subscription?.unsubscribe();
    this.focusMonitor.stopMonitoring(this.element.nativeElement);
  }

  renderTemplate(): void {
    if (!this.suggestionsTplRef) {
      console.error('NO NGXTA Template Found. Requires NG9');
      return;
    }
    this.viewContainer.createEmbeddedView(this.suggestionsTplRef);
    this.cdr.markForCheck();
  }

  listenAndSuggest(obs: Subject<KeyboardEvent>): void {
    this._subscription.add(
      obs
        .pipe(
          // tslint:disable-next-line: deprecation
          filter((e: KeyboardEvent) => validateNonCharKeyCode(e?.code)),
          map(toFormControlValue),
          debounceTime(this.taDebounce),
          // tslint:disable-next-line: deprecation
          concat(),
          distinctUntilChanged(),
          filter((query: string) => this.taAllowEmpty || hasCharacters(query)),
          tap((query: string) => (this.searchQuery = query)),
          switchMap((query: string) => {
            if (!this.isFocus) {
              this.hideSuggestions();
              return EMPTY;
            } else {
              return this.suggest(query);
            }
          })
        )
        .subscribe((results: string[] | any) => {
          if (this.isMinLength()) {
            this.displaySuggestions();
          } else {
            this.hideSuggestions();
          }
          this.assignResults(results);
          this.clearFormControl();
          this.suggestFirstValue();
        })
    );
  }

  assignResults(results: any[]): void {
    const labelForDisplay = this.taListItemLabel;
    this.resultsAsItems = results;
    this.results = results.map((item: string | any) => (labelForDisplay ? item[labelForDisplay] : item));
    this.suggestionIndex = NO_INDEX;
    if (!results || !results.length) {
      this.activeResult = this.searchQuery;
    }
  }

  filterEnterEvent(elementObs: Subject<KeyboardEvent>): void {
    this._subscription.add(
      elementObs.pipe(filter(isEnterKey)).subscribe((event: KeyboardEvent) => {
        if (this.showSuggestions) {
          this.handleSelectSuggestion(this.activeResult);
        }
      })
    );
  }

  navigateWithArrows(elementObs: Subject<KeyboardEvent>): void {
    this._subscription.add(
      elementObs
        .pipe(
          map((e: any) => e.key),
          filter((key: Key) => validateArrowKeys(key))
        )
        .subscribe((key: Key) => {
          this.updateIndex(key);
          this.displaySuggestions();
        })
    );
  }

  updateIndex(keyCode: string): void {
    this.suggestionIndex = resolveNextIndex(this.suggestionIndex, keyCode === Key.ArrowDown, this.results.length);
    this.scrollToIndex();
  }

  scrollToIndex(): void {
    const tileLength = 32;
    if (this.suggestionIndex > 3 && this.suggestionIndex < this.results.length) {
      this.scrollContainerValue = tileLength * this.suggestionIndex;
    } else {
      this.scrollContainerValue = 0;
    }
    this.mainContentDiv?.nativeElement.scroll(0, this.scrollContainerValue);
  }

  displaySuggestions(): void {
    this.showSuggestions = true;
    this.cdr.markForCheck();
  }

  suggest(query: string) {
    return this.request(query);
  }

  /**
   * peforms a jsonp/http request to search with query and params
   * @param query the query to search from the remote source
   */
  request(query: string) {
    if (this.isMinLength()) {
      return this.call(query);
    } else {
      return of([]);
    }
  }

  requestHttp(url: string, options: { params: HttpParams }) {
    const apiMethod = resolveApiMethod(this.taApiMethod);
    return this.http[apiMethod](url, options);
  }

  requestJsonp(url: string, options: { params: any }, callback = 'callback') {
    const params = options.params.toString();
    return this.http.jsonp(`${url}?${params}`, callback).pipe(map(toJsonpSingleResult), map(toJsonpFinalResults));
  }

  markIsActive(index: number, result: string) {
    const isActive = isIndexActive(index, this.suggestionIndex);
    if (isActive) {
      this.activeResult = result;
    }
    return isActive;
  }

  handleSelectionClick(suggestion: string, index: number): void {
    this.suggestionIndex = index;
    this.handleSelectSuggestion(suggestion);
  }

  handleSelectSuggestion(suggestion: string): void {
    this.hideSuggestions();
    const result = this.resultsAsItems.length ? this.resultsAsItems[this.suggestionIndex] : suggestion;
    const resolvedResult = this.suggestionIndex === NO_INDEX ? this.searchQuery : result;
    this.taSelected.emit(resolvedResult);
  }

  hideSuggestions(): void {
    this.showSuggestions = false;
  }

  hasItemTemplate() {
    return this.taItemTpl !== undefined;
  }

  createListSource(list: any[], query: string): Observable<string[]> {
    const sanitizedQuery = this.taCaseSensitive ? query : query.toLowerCase();
    const fieldsToExtract = this.taListItemField;
    return of(
      list.filter((item: string | any) => {
        return resolveItemValue(item, fieldsToExtract, this.taCaseSensitive).includes(sanitizedQuery);
      })
    );
  }

  isMinLength(): boolean {
    if (this.minLength === 0) {
      return true;
    }
    return this.searchQuery?.length >= this.minLength;
  }

  clearFormControl(): void {
    if (this.searchQuery?.length === 0) {
      this.taSelected.emit(null);
    }
  }

  private resetResults(): void {
    this.searchQuery = '';
    this.activeResult = '';
    this.results = [];
    this.resultsAsItems = [];
    this.clearFormControl();
  }

  private suggestFirstValue(): void {
    if (this.results?.length > 0) {
      const firstSuggestion = this.results[0];
      this.suggestionIndex = 0;
      this.activeResult = firstSuggestion;
    }
  }
}
