export interface InputOptionResource<T = null> {
  id: number;
  display_name: string;
  data: T;
}

export type FeedInputContext = string | object | undefined;

export const sortInputOptionResourceAz = (data: InputOptionResource[]): InputOptionResource[] => {
  return data.sort((a: InputOptionResource, b: InputOptionResource) => {
    return a.display_name.localeCompare(b.display_name);
  });
};

export const sortInputOptionResourcePopularityCount = (
  data: InputOptionResource<{ popularity_count: number }>[]
): InputOptionResource<{ popularity_count: number }>[] => {
  return data.sort(
    (a: InputOptionResource<{ popularity_count: number }>, b: InputOptionResource<{ popularity_count: number }>) => {
      if (a.data.popularity_count < b.data.popularity_count) {
        return 1;
      } else if (a.data.popularity_count > b.data.popularity_count) {
        return -1;
      }
      return 0;
    }
  );
};
