import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeedInputService } from '@app/@feed-inputs';
import { AbstractControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { SelectConfig, SelectData } from '@app/@pso-ui/select/select.interfaces';

@Component({
  selector: 'app-filters-select',
  templateUrl: './filters-select.component.html',
  styleUrls: ['./filters-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersSelectComponent implements OnInit {
  // @Select(FilterParamsState.isDefaultParams("orders"))
  // isDefaultParams$: Observable<boolean>;
  @Output() valueChanges = new EventEmitter<unknown>();
  @Input() width: number;
  @Input() entity: string;
  @Input() param: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() control: AbstractControl;
  @Input() isMultiselect = false;
  @Input() config: Partial<SelectConfig> = {
    search: false,
    prefixTemplate: null,
  };
  data$: Observable<SelectData<string>[]>;

  constructor(private store: Store, private feedInputService: FeedInputService) {}

  ngOnInit(): void {
    this.getOptions();
    // this.createControl();
    this.listenValueChange();
    // this.listenReset();
  }

  createControl(): void {
    // this.control = new FormControl(
    //   this.store.selectSnapshot(
    //     FilterParamsState.paramAsArray("orders", this.param)
    //   )
    // );
  }

  listenValueChange(): void {
    this.control.valueChanges.subscribe((data) => {
      if (this.control.touched) {
        this.valueChanges.emit({
          [this.param]: data ? data.toString() : '',
        });
      }
    });
  }

  listenReset(): void {
    // this.isDefaultParams$.pipe(filter((v) => v === true)).subscribe(() => {
    //   // TODO: this call API twice
    //   // TODO: duplicate for other components
    //   this.control.reset([], { emitEvent: false, onlySelf: true });
    // });
  }

  isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }

  private getOptions() {
    this.data$ = this.feedInputService.getList(this.entity).pipe(
      pluck('data'),
      map((data) => data.map((d) => ({ value: d.id, label: d.display_name })))
    );
  }
}
