import { Component, Input } from '@angular/core';

import { DispositionAuthResource } from '@data/disposition-auth/disposition-auth.model';

@Component({
  selector: 'app-authorization-handling-cell',
  templateUrl: './authorization-handling-cell.component.html',
  styleUrls: ['./authorization-handling-cell.component.scss'],
})
export class AuthorizationHandlingCellComponent {
  @Input() cell: DispositionAuthResource[];
}
