import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

let confirmation: boolean;

@Component({
  selector: 'wchfs-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string },
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}

  closeDialog(): void {
    this.dialogRef.close((confirmation = false));
  }

  confirmDelete(): void {
    this.dialogRef.close((confirmation = true));
  }
}
