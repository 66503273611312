import { Pipe, PipeTransform } from '@angular/core';
import { TyreAdditionalStateValue, TyreLogResource, TyreStateResource } from '@data/tyres/tyres.model';
import { TyreStateValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';

@Pipe({
  name: 'tyreState',
})
export class TyreStatePipe implements PipeTransform {
  transform(
    log: TyreLogResource,
    states: TyreAdditionalStateValue[]
  ): TyreStateResource<TyreStateValue | TyreAdditionalStateValue> {
    const found = log.tyre_additional_states.find((state) => {
      return states.includes(state.value);
    });

    if (found) {
      return found;
    }

    if (log.tyre_state) {
      return log.tyre_state;
    } else {
      return null;
    }
  }
}
