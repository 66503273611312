import { Component, Input } from '@angular/core';

@Component({
  selector: 'wchfs-expanded-list',
  templateUrl: './expanded-list.component.html',
  styleUrls: ['./expanded-list.component.scss'],
})
export class ExpandedListComponent {
  @Input() columns: string[];
}
