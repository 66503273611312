<div class="breadcrumbs-wrapper">
  <ng-container *ngFor="let chunk of chunks; let first = first; let last = last">
    <span
      class="breadcrumbs-text"
      [ngClass]="{ 'breadcrumbs-last': (last && !first) || last }"
      (click)="goToLink(chunk.route)"
      *ngIf="chunk.displayName; else singleChunkBlock"
      >{{ chunk.displayName | translate }}
      <span *ngIf="!last">/</span>
    </span>

    <ng-template #singleChunkBlock>
      <span class="breadcrumbs-text" [ngClass]="{ 'breadcrumbs-last': (last && !first) || last }">
        {{ chunk | translate }}
      </span>
    </ng-template>
  </ng-container>
</div>
