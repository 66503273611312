import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wchfs-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeComponent),
      multi: true,
    },
  ],
  host: {
    '[class.no-selected]': 'state === STATE.NO_SELECTED',
    '[class.during-selection]': 'state === STATE.DURING_SELECTING',
    '[class.selected]': 'state === STATE.SELECTED',
  },
})
export class RangeComponent implements OnInit, ControlValueAccessor {
  selectedOptions: SelectedOptions<RangeOption<any>> = {
    initial: null,
    final: null,
    values: [],
  };

  state = STATE.NO_SELECTED;

  @Input() options: RangeOption<any>[];

  public STATE = STATE;
  public inverted = false;

  constructor() {}

  ngOnInit(): void {}

  public onChangeFn = (selectedOptions: any) => {
    let active = false;
    let final: RangeOption<any>;

    selectedOptions.values = [];
    if (this.state === STATE.DURING_SELECTING) {
      this.options.forEach((item, index) => {
        if (item.active) {
          selectedOptions.values.push(item);
        }
      });
    }

    if (this.state === STATE.SELECTED) {
      this.options.forEach((item, index) => {
        if (item === selectedOptions.initial && !final) {
          final = selectedOptions.final;
          active = !active;
        }

        if (item === selectedOptions.final && !final) {
          final = selectedOptions.initial;
          active = !active;
          this.inverted = true;
        }

        item.active = active;

        if (active) {
          selectedOptions.values.push(item);
        }

        if (item === final) {
          active = !active;
        }
      });
    }

    console.log(selectedOptions);
  };

  public onTouchedFn = () => {};

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {}

  public writeValue(obj: any): void {}

  public onTouched() {
    this.onTouchedFn();
  }

  public onChange() {
    this.onChangeFn(this.selectedOptions);
  }

  public onClick(option: RangeOption<any>) {
    if (this.state === STATE.NO_SELECTED || this.state == STATE.SELECTED) {
      this.options.forEach((item) => this.resetItemProperties(item));
      this.inverted = false;
      this.selectedOptions.final = null;
      this.selectedOptions.initial = option;
      option.initial = true;
      option.extreme = true;
      option.active = true;
      this.state = STATE.DURING_SELECTING;
    } else {
      this.selectedOptions.final = option;
      option.final = true;
      option.extreme = true;
      option.active = true;
      this.state = STATE.SELECTED;
    }

    this.onChange();
  }

  resetItemProperties(item: any) {
    item.extreme = false;
    item.active = false;
    item.final = false;
    item.initial = false;
  }
}

export interface RangeOption<T> {
  value: T;
  label: string;
  active?: boolean;
  extreme?: boolean;
  initial?: boolean;
  final?: boolean;
}

export interface SelectedOptions<T> {
  initial: T;
  final: T;
  values: T[];
}

export enum STATE {
  NO_SELECTED = 'no-selected',
  DURING_SELECTING = 'during-selecting',
  SELECTED = 'selected',
}
