import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';

@Directive({
  selector: '[wchfsLoading]',
})
export class LoadingDirective implements OnInit, OnDestroy {
  private _reguestMapSubscription: Subscription;
  private _loader: HTMLElement;
  private _isLoaderActive = false;
  @Input() wchfsLoading: string;
  @Input() maxLoaderTime = 10000;
  @Input() useClass: string;
  @Input() regexMatch: string;
  constructor(
    private readonly loadingService: LoadingService,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit() {
    this.styleHostElement();
    this.createLoader();
    this.styleLoaderPosition();
    if (this.wchfsLoading || this.regexMatch) {
      this._reguestMapSubscription = this.loadingService
        .getReguestArray()
        .pipe(skip(1), debounceTime(250))
        .subscribe((reqs: string[]) => {
          let isReqInTable;
          reqs.forEach((req) => {
            if (this.regexMatch) {
              isReqInTable = req.match(new RegExp(this.regexMatch));
            } else {
              isReqInTable = req.includes(this.wchfsLoading);
            }
          });
          isReqInTable ? this.showLoader() : this.hideLoader();
        });
    }
  }

  private styleHostElement(): void {
    this.el.nativeElement.style.position = 'relative';
  }

  private createLoader(): void {
    this._loader = this.renderer.createElement('div') as HTMLElement;
    const loaderDot1 = this.renderer.createElement('div') as HTMLElement;
    const loaderDot2 = this.renderer.createElement('div') as HTMLElement;
    const loaderDot3 = this.renderer.createElement('div') as HTMLElement;
    this.renderer.addClass(this._loader, this.useClass ? this.useClass : 'lds-ellipsis');
    this.renderer.appendChild(this._loader, loaderDot1);
    this.renderer.appendChild(this._loader, loaderDot2);
    this.renderer.appendChild(this._loader, loaderDot3);
  }

  private styleLoaderPosition(): void {
    this.renderer.setStyle(this._loader, 'position', 'absolute');
    this.renderer.setStyle(this._loader, 'top', '50%');
    this.renderer.setStyle(this._loader, 'left', '50%');
    this.renderer.setStyle(this._loader, 'transform', 'translate(-75%, -50%)');
    this.renderer.setStyle(this._loader, 'z-index', '5000');
  }

  private hideLoader(): void {
    if (this._isLoaderActive) {
      const loaderParent = this.el.nativeElement;
      this._isLoaderActive = false;
      this.renderer.removeChild(loaderParent, this._loader);
      this.renderer.removeClass(loaderParent, 'loading');
    }
  }

  private showLoader(): void {
    if (!this._isLoaderActive) {
      const loaderParent = this.el.nativeElement;
      this._isLoaderActive = true;
      this.renderer.appendChild(loaderParent, this._loader);
      this.renderer.addClass(loaderParent, 'loading');
      this.overtimeLoaderFuse();
    }
  }

  private overtimeLoaderFuse(): void {
    setTimeout(() => {
      if (this._isLoaderActive) {
        this.hideLoader();
      }
    }, this.maxLoaderTime);
  }

  ngOnDestroy() {
    this._reguestMapSubscription.unsubscribe();
  }
}
