import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sortable-header',
  templateUrl: './sortable-header.component.html',
  styleUrls: ['./sortable-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortableHeaderComponent {
  @Input() sorting: Map<string, string>;
  @Input() name: string;
  @Output() sort = new EventEmitter<string>();
  @HostBinding('class') get activeClass(): string {
    return this.sorting.has(this.name) && this.sorting.get(this.name) !== null ? 'sorted' : 'unsorted';
  }
}
