<div class="coordinates">
  <div class="coordinate coordinate--lat">
    <wchfs-icon svgIcon="pso:map_pin_blue_m" size="24"></wchfs-icon>
    <p>{{ lat }}</p>
  </div>
  <div class="coordinate coordinate--lng">
    <wchfs-icon svgIcon="pso:map_pin_blue_m" size="24"></wchfs-icon>
    <p>{{ lng }}</p>
  </div>
</div>
