<ng-container *ngIf="!productTyre">
  <div class="tyre-top">
    <!-- <app-new-tyre-badge></app-new-tyre-badge> TODO is there a way to determine if tyre is new -->
    <app-tyre-data [tyre]="tyre?.product_tyre"></app-tyre-data>
  </div>
  <div *ngIf="tyre?.product_tyre?.tyre_params?.length" class="tyre-params">
    <ng-container *ngFor="let type of TyreParamType.all(); let last = last">
      <ng-container *ngIf="TyreParam.getParamsByType(tyre?.product_tyre?.tyre_params, type)?.length > 0">
        <app-tyre-params
          [tyreParams]="TyreParam.getParamsByType(tyre?.product_tyre?.tyre_params, type)"
          [showSeparator]="!last"
        ></app-tyre-params>
      </ng-container>
    </ng-container>
  </div>
  <div class="tyre-details">
    <app-tyre-fuel-efficient
      *ngIf="tyre?.rolling_resistance || tyre?.product_tyre?.rolling_resistance"
      [rollingResistance]="tyre?.rolling_resistance ??  tyre?.product_tyre?.rolling_resistance"
    ></app-tyre-fuel-efficient>
    <app-tyre-adhesion
      *ngIf="tyre?.adhesion || tyre?.product_tyre?.adhesion"
      [adhesion]="tyre?.adhesion ?? tyre?.product_tyre?.adhesion"
    ></app-tyre-adhesion>
    <app-tyre-noise-level
      *ngIf="tyre?.noise_level || tyre?.noise || tyre?.product_tyre?.noise_level || tyre?.product_tyre?.noise"
      [noiseLevel]="tyre?.noise_level ?? tyre?.product_tyre?.noise_level"
      [noise]="tyre?.noise ?? tyre?.product_tyre?.noise"
    ></app-tyre-noise-level>
  </div>
</ng-container>

<ng-container *ngIf="productTyre">
  <div class="tyre-top">
    <!-- <app-new-tyre-badge></app-new-tyre-badge> TODO is there a way to determine if tyre is new -->
    <app-tyre-data [tyre]="productTyre"></app-tyre-data>
  </div>
  <div class="tyre-params">
    <ng-container *ngFor="let type of TyreParamType.all(); let last = last">
      <ng-container *ngIf="TyreParam.getParamsByType(productTyre?.tyre_params, type)?.length > 0">
        <app-tyre-params
          [tyreParams]="TyreParam.getParamsByType(productTyre?.tyre_params, type)"
          [showSeparator]="!last"
        ></app-tyre-params>
      </ng-container>
    </ng-container>
  </div>
  <div class="tyre-details">
    <app-tyre-fuel-efficient
      *ngIf="productTyre?.rolling_resistance"
      [rollingResistance]="productTyre?.rolling_resistance"
    ></app-tyre-fuel-efficient>
    <app-tyre-adhesion *ngIf="productTyre?.adhesion" [adhesion]="productTyre?.adhesion"></app-tyre-adhesion>
    <app-tyre-noise-level
      *ngIf="productTyre.noise_level || productTyre?.noise"
      [noiseLevel]="productTyre?.noise_level"
      [noise]="productTyre?.noise"
    ></app-tyre-noise-level>
  </div>
</ng-container>
