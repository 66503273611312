<section class="tab-panel">
  <ul class="nav">
    <li
      *ngFor="let tab of tabs"
      (click)="tabClicked(tab)"
      [class.active]="tab.active"
      [appAnchorTarget]="tab.appAnchorTarget"
      [routerLink]="tab.routerLink"
      title="{{ tab.title }}"
      [style.color]="!tab.active && fullwidth ? 'var(--grey-4)' : null"
    >
      <span [style.transform]="tab.active ? 'translateX(-' + calculateBoldShift(tab.title.length) + 'px)' : null">
        {{ tab.title }}
      </span>
    </li>
  </ul>
</section>
