import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'countingHoursLeft',
})
export class CountingHoursLeftPipe implements PipeTransform {
  transform(value: string, countdownFrom: number): unknown {
    const date = DateTime.fromISO(value);
    const timeBetweenTwoDates = DateTime.now().diff(date, 'hours').hours;
    if (timeBetweenTwoDates < countdownFrom) {
      return DateTime.now()
        .minus({ hours: countdownFrom - timeBetweenTwoDates })
        .toRelative()
        .replace('temu', '');
    } else {
      return 0;
    }
  }
}
