import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TyreAdditionalState, TyreAdditionalStateValue, TyreStateResource } from '@data/tyres/tyres.model';

@Component({
  selector: 'app-deposit-action-status',
  templateUrl: './deposit-action-status.component.html',
  styleUrls: ['./deposit-action-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositActionStatusComponent {
  @Input() status: TyreStateResource<TyreAdditionalStateValue>;
  TyreAdditionalState = TyreAdditionalState;
}
