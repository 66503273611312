import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabItemComponent {
  @Input() title: string;
  @Input() appAnchorTarget: any = undefined;
  @Output() activeChange = new EventEmitter<boolean>();
  @Input() data: any;
  @Input() routerLink: string;
  @Input() set active(value: boolean) {
    this._active = value;
    this.activeChange.emit(value);
  }
  get active(): boolean {
    return this._active;
  }
  private _active: boolean;
}
