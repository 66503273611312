import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ListData } from '@shared/interfaces/list-data';
import { PagedResource } from '@shared/interfaces/paged-resource';

@Component({
  selector: 'app-pagination-above-table',
  templateUrl: './pagination-above-table.component.html',
  styleUrls: ['./pagination-above-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationAboveTableComponent {
  @Output() setPerPage = new EventEmitter();
  @Output() setPage = new EventEmitter();
  @Input() listData: ListData<PagedResource<unknown>>;
}
