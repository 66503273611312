import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-no-entries',
  templateUrl: './table-no-entries.component.html',
  styleUrls: ['./table-no-entries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableNoEntriesComponent {
  @Input() title = 'select-a-different-value-and-search-again';
  @Input() description = 'select-other-values​-and-search-again';
}
