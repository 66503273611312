<wchfs-form-field class="control control--dot">
  <input
    [dataCy]="'dot'"
    [placeholder]="2020"
    [maxlength]="4"
    [formControl]="control"
    [customErrors]="{
      pattern: 'incorrect-dot-format' | translate,
      required: ' '
    }"
    (keydown)="DOTKeydownValue($event)"
    wchfsInput
    type="text"
  />
</wchfs-form-field>
