import { Component, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';
import { NavigationService } from '../navigation.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { Observable } from 'rxjs';

@Component({
  selector: 'wchfs-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  animations: [
    trigger('toggleLeft', [
      state(
        'open',
        style({
          width: '265px',
        })
      ),
      state(
        'close',
        style({
          width: '60px',
        })
      ),
      transition('* => *', [
        group([
          query('@toggleDate', animateChild(), { optional: true }),
          query('@togglePseudoTop', animateChild(), { optional: true }),
          animate('320ms'),
        ]),
      ]),
    ]),
    trigger('togglePseudoTop', [
      state(
        'open',
        style({
          width: 'calc(100vw - 265px - 1px)', // 100vw - left open sidebar width - 1px of border
          transform: 'translateX(265px)',
        })
      ),
      state(
        'close',
        style({
          width: 'calc(100vw - 59px - 1px)', // 100vw - left close sidebar width - 1px of border
          transform: 'translateX(60px)',
        })
      ),
      transition('* => *', [animate('300ms')]),
    ]),
    trigger('toggleDate', [
      state(
        'open',
        style({
          opacity: 1,
          height: '113px',
          paddingTop: '30px',
          marginBottom: '30px',
        })
      ),
      state(
        'close',
        style({
          opacity: 0,
          height: '0px',
          paddingTop: '0px',
          marginBottom: '0px',
          borderBottom: '0px',
        })
      ),
      transition('open => close', [animate('200ms')]),
      transition('close => open', [animate('300ms 450ms')]),
    ]),
  ],
})
export class NavComponent implements OnInit {
  today: Moment;
  isExpandMenu$: Observable<boolean>;

  @Input() isHamburger: boolean = false;
  @HostBinding('class.nav-menu-hamburger') isHamburgerMenu: boolean = false;

  constructor(private navigationService: NavigationService, private renderer: Renderer2) {
    this.today = moment();
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'body');
    this.isHamburgerMenu = this.isHamburger;
    this.isExpandMenu$ = this.navigationService.isExpandMenu$;
  }

  toggleLeftSidebar() {
    this.navigationService.toggleExpandMenu();
  }
}
