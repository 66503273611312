import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { VehicleFilterParams } from '@app/@data/vehicles/vehicles.model';
import { VehiclesService } from '@app/@data/vehicles/vehicles.service';
import { FilterParams, PsoFilter } from '@data/filtering/filtering.interface';
import { SelectData } from '@pso-ui/select/select.interfaces';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  template: '',
})
export class VehicleSelectBaseComponent implements PsoFilter<SelectData<unknown>[]>, OnInit {
  @Input() control: FormControl;
  @Input() label = 'vehicles';
  @Input() set params(value: Partial<VehicleFilterParams>) {
    this._params = { ...this._params, ...value };

    if (!this.control?.disabled) {
      this.data$ = this.getData(this.params);
    } else {
      this.data$ = of([]);
    }
  }

  get params(): Partial<VehicleFilterParams> {
    return this._params;
  }

  data$: Observable<SelectData<number>[]>;

  private _params: Partial<VehicleFilterParams> = { paginate: '0' };

  constructor(private vehiclesService: VehiclesService) {}

  ngOnInit(): void {
    if (!this.control?.disabled) {
      this.data$ = this.getData(this.params);
    } else {
      this.data$ = of([]);
    }
  }

  getData(params?: Partial<VehicleFilterParams>): Observable<SelectData<unknown>[]> {
    return this.vehiclesService.get(params as FilterParams).pipe(
      map((data) => {
        return data.data.map((d) => ({ value: d.id, label: `${d.name} (${d.id})` }));
      })
    );
  }
}
