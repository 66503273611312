<div class="tab-panel">
  <div class="tab-labels">
    <button
      *ngFor="let l of tabs; let i = index"
      [disabled]="l.label.disabled"
      [ngClass]="{
        label: true,
        'label--active': activeTabIndex === i,
        'label--with-error': l.notificationErrors?.length > 0 && l.initialTabFormValue,
        'label--witch-changes': l.notification && l.initialTabFormValue
      }"
      (click)="changeTab(i)"
    >
      {{ l.label.name }}
    </button>
  </div>
  <div class="tab-body">
    <ng-content></ng-content>
  </div>
</div>
