import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaResource } from '@data/media/media.model';

@Component({
  selector: 'app-attachments-or-empty-attachment',
  templateUrl: './attachments-or-empty-attachment.component.html',
  styleUrls: ['./attachments-or-empty-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsOrEmptyAttachmentComponent {
  @Input() medias: MediaResource[];
}
