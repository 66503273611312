<!--<div>-->
<!--  <div class="snackbar-message">-->
<!--    <wchfs-icon [svgIcon]="snackbarIconName" fill="primary" size="x-small"></wchfs-icon>-->
<!--    <p class="message">{{ data.message }}</p>-->
<!--  </div>-->
<!--  <div class="snackbar-actions">-->
<!--    <span (click)="dismiss()" class="snackbar-action-button" *ngIf="data.withDismissButton; else crossButton"-->
<!--      >Dismiss</span-->
<!--    >-->
<!--    <ng-container *ngFor="let actionButton of actionButtons">-->
<!--      <span-->
<!--        (click)="actionHandler(actionButton.handler)"-->
<!--        [ngClass]="'snackbar-action-button-' + data.type"-->
<!--        class="snackbar-action-button"-->
<!--      >-->
<!--        {{ actionButton.actionMessage }}-->
<!--      </span>-->
<!--    </ng-container>-->
<!--    <ng-template #crossButton>-->
<!--      <wchfs-icon-->
<!--        (click)="dismiss()"-->
<!--        class="cross-icon"-->
<!--        fill="grey2"-->
<!--        size="x-small"-->
<!--        svgIcon="wchfs:cross"-->
<!--      ></wchfs-icon>-->
<!--    </ng-template>-->
<!--  </div>-->
<!--</div>-->

<!--<div>-->
<!--  <div class="snackbar-message">-->
<!--    <wchfs-icon [svgIcon]="snackbarIconName" class="snackbar-icon-type" fill="primary" size="x-small"></wchfs-icon>-->
<!--    <p class="message">{{ data.message }}</p>-->
<!--    <span-->
<!--      (click)="dismiss()"-->
<!--      *ngIf="data.withDismissButton; else crossButton"-->
<!--      [ngClass]="'snackbar-action-button-' + data.type"-->
<!--      class="snackbar-action-button"-->
<!--    >-->
<!--      Dismiss-->
<!--    </span>-->
<!--    <ng-container *ngFor="let actionButton of actionButtons">-->
<!--      <button pso-button color="primary-b-blue"-->
<!--        (click)="actionHandler(actionButton.handler)"-->
<!--        [ngClass]="'snackbar-action-button-' + data.type"-->
<!--      >-->
<!--        {{ actionButton.actionMessage }}-->
<!--      </button>-->
<!--    </ng-container>-->
<!--    <ng-template #crossButton>-->
<!--      <wchfs-icon-->
<!--        class="close-icon"-->
<!--        (click)="dismiss()"-->
<!--        class="cross-icon"-->
<!--        fill="grey2"-->
<!--        size="x-small"-->
<!--        svgIcon="wchfs:cross"-->
<!--      ></wchfs-icon>-->
<!--    </ng-template>-->
<!--  </div>-->
<!--</div>-->

<div class="snackbar">
  <div class="snackbar__top">
    <div class="snackbar__icon">
      <wchfs-icon [svgIcon]="snackbarIconName" fill="primary" size="x-small"></wchfs-icon>
    </div>
    <div class="snackbar__message">{{ data.message }}</div>
    <div class="snackbar__x">
      <wchfs-icon (click)="dismiss()" size="x-small" svgIcon="wchfs:cross"></wchfs-icon>
    </div>
  </div>

  <div class="snackbar__bottom">
    <div class="actions">
      <button
        (click)="actionHandler(actionButton.handler)"
        pso-button
        size="s"
        color="supporting-5-light-blue"
        *ngFor="let actionButton of actionButtons"
      >
        {{ actionButton.actionMessage }}
      </button>
    </div>
  </div>
</div>
