import { ChangeDetectionStrategy, Component } from '@angular/core';
import { InvoiceSelectBaseComponent } from '../invoice-select.base.component';

@Component({
  selector: 'app-invoice-single-select',
  templateUrl: './invoice-single-select.component.html',
  styleUrls: ['./invoice-single-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceSingleSelectComponent extends InvoiceSelectBaseComponent {}
