import { AbstractControl } from '@angular/forms';

export class CustomValidators {
  matchValidator(firstControlName: any, secondControlName: any): any {
    return (AC: AbstractControl): any => {
      const firstControlValue = AC.get(firstControlName).value; // to index value in input tag
      const secondControlValue = AC.get(secondControlName).value; // to index value in input tag
      if (firstControlValue !== secondControlValue) {
        AC.get(secondControlName).setErrors({ MatchFields: true });
      } else {
        return null;
      }
    };
  }
}
