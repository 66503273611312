import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private isExpandMenu = new BehaviorSubject<boolean>(true);
  isExpandMenu$ = this.isExpandMenu.asObservable();

  constructor() {}

  toggleExpandMenu(): void {
    this.isExpandMenu.next(!this.isExpandMenu.value);
  }

  setExpandMenu() {
    this.isExpandMenu.next(true);
  }
}
