import { ChangeDetectionStrategy, Component } from '@angular/core';
import { VehicleSelectBaseComponent } from '../vehicle-select.base.component';

@Component({
  selector: 'app-vehicle-multi-select',
  templateUrl: './vehicle-multi-select.component.html',
  styleUrls: ['./vehicle-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleMultiSelectComponent extends VehicleSelectBaseComponent {}
