import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { PsoBaseButtonComponent } from '../pso-base-button.component';

@Component({
  selector: 'button[pso-icon-button]',
  templateUrl: './pso-icon-button.component.html',
  styleUrls: ['../pso-base-button.component.scss', './pso-icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsoIconButtonComponent extends PsoBaseButtonComponent {
  @HostBinding('style.padding-left.px') get paddingLeft(): number {
    return 0;
  }

  @HostBinding('style.padding-right.px') get paddingRight(): number {
    return 0;
  }
}
