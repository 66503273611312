import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompleteWheel, CompleteWheelValue } from '@pages-and-modals/add-new-tyres-modal/add-new-tires-modal.model';

@Component({
  selector: 'app-complete-wheel',
  templateUrl: './complete-wheel.component.html',
  styleUrls: ['./complete-wheel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompleteWheelComponent {
  @Input() completeWheel: CompleteWheelValue;
  protected readonly CompleteWheel = CompleteWheel;
}
