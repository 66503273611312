<div class="container" appDnd (fileDropped)="onFileDropped($event)" [style.width]="config.width">
  <div class="content">
    <input
      [dataCy]="'file-uploader-file-input'"
      type="file"
      #fileDropRef
      id="fileDropRef"
      [attr.multiple]="config.multiple"
      [attr.accept]="acceptedExtensions.join(',')"
      (change)="fileBrowseHandler($event.target.files)"
    />

    <div class="content-icon">
      <wchfs-icon size="40" svgIcon="pso:input-file"></wchfs-icon>
    </div>
    <div class="content-header">
      <span>
        {{ +config?.multiple + 1 | i18nPlural: messageMapping.text | translate }}
        <span [dataCy]="'file-uploader-file-browse-open-button'" class="blue-text" (click)="fileDropRef.click()">{{
          'drag-and-drop.u-computer' | translate
        }}</span>
      </span>
    </div>
    <div class="content-text">
      <span>
        {{ config?.accept?.length | i18nPlural: messageMapping.acceptedFormat | translate }}
        <span class="content-text-formats">{{ extensionsInfo | uppercase }}</span>
      </span>
    </div>

    <ng-content></ng-content>
  </div>
</div>
