import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompletionTypeSlug } from '@data/tyres/tyres.model';
import { RimTypeValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import {
  DepositFullWheelLabel,
  FullWheelLabel,
  FullWheelLabelContext,
  FullWheelLabelContextValue,
  VehicleFullWheelLabel,
} from '@pso-ui/full-wheel-label/full-wheel-label.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-full-wheel-label',
  templateUrl: './full-wheel-label.component.html',
  styleUrls: ['./full-wheel-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullWheelLabelComponent {
  get context(): FullWheelLabelContextValue {
    return this._context;
  }
  @Input()
  set context(value: FullWheelLabelContextValue) {
    this._context = value;
    this.fullWheelLabel = this.fullWheelLabelFactory();
  }
  get rimType(): RimTypeValue {
    return this._rimType;
  }
  @Input()
  set rimType(value: RimTypeValue) {
    this._rimType = value;
    this.fullWheelLabel = this.fullWheelLabelFactory();
  }
  get completionType(): CompletionTypeSlug {
    return this._completionType;
  }
  @Input()
  set completionType(value: CompletionTypeSlug) {
    this._completionType = value;
    this.fullWheelLabel = this.fullWheelLabelFactory();
  }
  private _context: FullWheelLabelContextValue;
  private _completionType: CompletionTypeSlug;
  private _rimType: RimTypeValue;
  fullWheelLabel: DepositFullWheelLabel;

  constructor(protected translateService: TranslateService) {}

  private fullWheelLabelFactory(): FullWheelLabel {
    switch (this.context) {
      case FullWheelLabelContext.DEPOSIT: {
        const completionTypeTranslate = this._completionType
          ? this.translateService?.instant(this._completionType)
          : null;
        const rimTypeTranslate = this._rimType ? this.translateService?.instant(this._rimType) : null;
        return new DepositFullWheelLabel(
          this._completionType,
          this._rimType,
          completionTypeTranslate,
          rimTypeTranslate
        );
      }
      case FullWheelLabelContext.VEHICLE: {
        const completionTypeTranslate = this._completionType
          ? this.translateService?.instant(this._completionType)
          : null;
        const rimTypeTranslate = this._rimType ? this.translateService?.instant(this._rimType) : null;
        return new VehicleFullWheelLabel(
          this._completionType,
          this._rimType,
          completionTypeTranslate,
          rimTypeTranslate
        );
      }
      default: {
        return null;
      }
    }
  }
}
