import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-button-warn-arrow-left',
  templateUrl: './button-warn-arrow-left.component.html',
  styleUrls: ['./button-warn-arrow-left.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonWarnArrowLeftComponent {
  @Input() disabled = false;
  element: ElementRef;

  constructor(private elementRef: ElementRef) {
    this.element = elementRef;
  }
}
