import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() isLoading = false;
  @Input() size = 1;
  @Input() message: string | undefined;
  @Input() absolute: false;
  @HostBinding('class') get class(): string {
    return this.absolute ? 'absolute' : '';
  }
}
