import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { BadgeSize } from './badge.model';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() removable = false;
  @Input() size = BadgeSize.SIZE_M;
  @Input() text: string;
  @Input() @HostBinding('style.backgroundColor') backgroundColor: string;
  @Input() @HostBinding('style.color') color = 'var(--primary-w-white)';
  BadgeSize = BadgeSize;
}
