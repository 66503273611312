<wchfs-form-field [class.disabled]="control?.disabled">
  <wchfs-label>{{ label | translate }}</wchfs-label>

  <app-base-multi-select
    dataCy="disposition-multi-select"
    [formControl]="control"
    [data]="data$ | async"
    [placeholder]="'choose-and-search' | translate"
    [config]="{
      search: true
    }"
  ></app-base-multi-select>
</wchfs-form-field>
