import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TyrePosition, TyrePositionSlug } from '@data/tyres/tyres.model';

@Component({
  selector: 'app-tire-position-designation',
  templateUrl: './tire-position-designation.component.html',
  styleUrls: ['./tire-position-designation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TirePositionDesignationComponent {
  @Input() position: TyrePositionSlug;
  TyrePosition = TyrePosition;
}
