import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountComponent implements OnInit {
  @Input() higherPrice: number;
  @Input() lowerPrice: number;
  discount = 0;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.calculate();
  }

  calculate(): void {
    const discount = this.higherPrice - this.lowerPrice;
    this.discount = discount < 0 ? 0 : discount;
    this.changeDetectorRef.markForCheck();
  }
}
