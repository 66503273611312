<div [ngSwitch]="size">
  <div *ngSwitchCase="BadgeSize.SIZE_S" [ngClass]="'badge--size-' + BadgeSize.SIZE_S" class="badge">
    <span>{{ text }}</span>
  </div>
  <div *ngSwitchCase="BadgeSize.SIZE_M" [ngClass]="'badge--size-' + BadgeSize.SIZE_M" class="badge">
    <span>{{ text }}</span>
  </div>
  <div
    *ngSwitchCase="BadgeSize.SIZE_L"
    [class.removable]="removable"
    [ngClass]="'badge--size-' + BadgeSize.SIZE_L"
    class="badge"
  >
    <span>{{ text }}</span>
    <div class="icon-wrap" *ngIf="removable">
      <wchfs-icon svgIcon="pso:close_m" size="24"></wchfs-icon>
    </div>
  </div>
</div>
