import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreadcrumbChunk, DynamicBreadcrumbsService } from './dynamic-breadcrumbs.service';

@Component({
  selector: 'wchfs-dynamic-breadcrumbs',
  templateUrl: './dynamic-breadcrumbs.component.html',
  styleUrls: ['./dynamic-breadcrumbs.component.scss'],
})
export class DynamicBreadcrumbsComponent implements OnInit {
  @Input() chunks: BreadcrumbChunk[] | string[];
  breadcrumbs$: Observable<BreadcrumbChunk[] | string[]>;

  constructor(private readonly breadcrumbService: DynamicBreadcrumbsService, private router: Router) {}
  ngOnInit(): void {
    if (this.chunks) {
      this.breadcrumbService.getChunks(this.chunks);
      this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
    } else {
      this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
    }
  }

  goToLink(route: string | undefined): void {
    if (route) {
      this.router.navigateByUrl(route);
    }
  }
}
