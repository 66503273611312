import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FeedInputService, InputOptionResource } from '@app/@feed-inputs';
import { PsoFilter } from '@data/filtering/filtering.interface';

import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { SelectData } from '@pso-ui/select/select.interfaces';

@Component({
  template: '',
})
export class VehicleTypeSelectBaseComponent implements PsoFilter<SelectData<number>[]>, OnInit {
  @Input() control: FormControl;
  @Input() label = 'vehicle-type';
  data$: Observable<SelectData<number>[]>;

  constructor(private feedInputService: FeedInputService) {}

  ngOnInit(): void {
    this.data$ = this.getData();
  }

  getData(): Observable<SelectData<number>[]> {
    return this.feedInputService.getList('tyre_types').pipe(
      pluck('data'),
      map((data) => {
        return data.map((d: InputOptionResource<{ code: string }>) => ({
          value: d.id,
          label: d.display_name,
        }));
      })
    );
  }
}
