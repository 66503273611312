import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductTyreResource } from '@data/product-tyres/product-tyres.model';

@Component({
  selector: 'app-tyre-data',
  templateUrl: './tyre-data.component.html',
  styleUrls: ['./tyre-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TyreDataComponent {
  @Input() tyre: ProductTyreResource;
}
