<wchfs-form-field>
  <wchfs-label>{{ 'tread-depth' | translate }}</wchfs-label>
  <wchfs-icon size="15" svgIcon="pso-legacy:depozyty_ico-1" wchfsPrefix> </wchfs-icon>
  <input
    type="text"
    [formControl]="internalControl"
    placeholder="-.-"
    wchfsInput
    [style.padding-right]="'40px'"
    autocomplete="off"
    (keydown)="check($event, input.value)"
    [customErrors]="{
      max: 'Max. ' + max + 'mm',
      treadDepthExceeded: 'the-entered-tread-depth-is-too-great' | translate
    }"
    #input
  />
  <span class="suffix" wchfsMaskTypeEnd [style.pointer-events]="'none'">{{ 'mm' | translate }}</span>
</wchfs-form-field>
