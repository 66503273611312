import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-heading-with-button',
  templateUrl: './heading-with-button.component.html',
  styleUrls: ['./heading-with-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingWithButtonComponent {
  @Input() text: string;
  @Input() buttonTemplate: TemplateRef<unknown>;
}
