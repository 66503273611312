import { Create, Delete, Id, Update } from '@app/@core/http/crud-model';
import { HttpClient } from '@angular/common/http';
import { DataResource } from '@app/@shared/interfaces/data-resource';
import { Observable } from 'rxjs';
import { ExternalInvoicePostRequest } from './external-invoice.model';
import { InvoiceResource } from '../invoices/invoice.model';

const ENDPOINT = '/external';

export class ExternalInvoicesService
  implements
    Create<ExternalInvoicePostRequest, InvoiceResource>,
    Update<ExternalInvoicePostRequest, InvoiceResource>,
    Delete<Id> {
  constructor(private httpClient: HttpClient, private endpoint: string) {}

  create(params: ExternalInvoicePostRequest): Observable<DataResource<InvoiceResource>> {
    return this.httpClient.post<DataResource<InvoiceResource>>(`${this.endpoint}${ENDPOINT}`, params);
  }

  update(id: Id, params: ExternalInvoicePostRequest): Observable<DataResource<InvoiceResource>> {
    return this.httpClient.put<DataResource<InvoiceResource>>(`${this.endpoint}${ENDPOINT}/${id}`, params);
  }

  delete(id: Id): Observable<DataResource<null>> {
    return this.httpClient.delete<DataResource<null>>(`${this.endpoint}${ENDPOINT}/${id}`);
  }
}
