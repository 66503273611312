import { Component, ElementRef, Optional, Renderer2, ViewChild } from '@angular/core';
import { FormFieldComponent } from '../../../form/form-field/form-field.component';

@Component({
  selector: 'wchfs-password-icon',
  templateUrl: './wchfs-password-icon.component.html',
  styleUrls: ['./wchfs-password-icon.component.scss'],
})
export class WchfsPasswordIconComponent {
  showPassword = false;
  @ViewChild('wchfsInfix') infix: ElementRef;

  constructor(private renderer: Renderer2, @Optional() private parentComponent: FormFieldComponent) {}

  changePasswordIcon() {
    this.showPassword = !this.showPassword;
    const passwordType = this.showPassword ? 'text' : 'password';
    const inputElement = this.parentComponent.infix.nativeElement.getElementsByTagName('input')[0];
    this.renderer.setProperty(inputElement, 'type', passwordType);
  }
}
