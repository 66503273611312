import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-and-bold-data',
  templateUrl: './header-and-bold-data.component.html',
  styleUrls: ['./header-and-bold-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderAndBoldDataComponent {
  @Input() header: string;
  @Input() text: string;
  @Input() bold: string;
}
