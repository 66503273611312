import { Pipe, PipeTransform } from '@angular/core';
import { RolePrefixService } from '@shared/services/role-prefix.service';

@Pipe({
  name: 'rolePrefix',
})
export class RolePrefixPipe implements PipeTransform {
  constructor(private rolePrefixService: RolePrefixService) {}

  transform(value: string, ...args: unknown[]): string {
    return this.rolePrefixService.transform(value);
  }
}
