<a
  class="icon-wrap"
  target="_blank"
  *ngIf="media; else noAttachment"
  (click)="download(media.url)"
  [style.cursor]="'pointer'"
>
  <wchfs-icon [color]="'primary'" svgIcon="pso-legacy:print_ico" size="18"></wchfs-icon>
</a>

<ng-template #noAttachment>
  <div class="icon-wrap">
    <wchfs-icon svgIcon="pso-legacy:print_ico" size="18"></wchfs-icon>
  </div>
</ng-template>
