import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompletionType, TyreLogResource, TyreLogWithChange, TyreParam, TyreParamType } from '@data/tyres/tyres.model';
import { DispositionOrigin, DispositionOriginValue, DispositionResource } from '@data/dispositions/dispositions.model';
import { TyreState } from '@data/disposition-add-edit/disposition-add-edit.interfaces';

@Component({
  selector: 'app-deposits-operations-table',
  templateUrl: './deposits-operations-table.component.html',
  styleUrls: ['./deposits-operations-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositsOperationsTableComponent {
  @Input() set disposition(value: DispositionResource) {
    this.setTyreLogsWithChanges(value.tyre_logs, value.origin.value);
  }

  TyreParamType = TyreParamType;
  TyreParam = TyreParam;
  tyreLogsWithChanges: TyreLogWithChange[];
  protected readonly TyreState = TyreState;
  protected readonly CompletionType = CompletionType;

  private setTyreLogsWithChanges(tyreLogs: TyreLogResource[], origin: DispositionOriginValue): void {
    if (origin === DispositionOrigin.DEPOSIT_EXTENSION) {
      this.tyreLogsWithChanges = tyreLogs.map(
        (tl) => new TyreLogWithChange(tl, tl.completion_type === CompletionType.RIM_ONLY)
      );
      return;
    }
    this.tyreLogsWithChanges = tyreLogs
      ?.filter((tyreLog: TyreLogResource) => tyreLog.tyre_state_from !== tyreLog.tyre_state?.value)
      .map((tl) => new TyreLogWithChange(tl, tl.completion_type === CompletionType.RIM_ONLY));
  }
}
