import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Service } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { DispositionItemProductData, ProductData, ServiceProductData } from '@pso-ui/product-data/product-data.model';
import { DispositionItemResource } from '@data/authorization/authorization.model';

@Component({
  selector: 'app-product-data',
  templateUrl: './product-data.component.html',
  styleUrls: ['./product-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDataComponent {
  data: ProductData;
  @Input() set service(value: Service) {
    this.data = new ServiceProductData(value);
  }
  @Input() set dispositionItem(value: DispositionItemResource) {
    this.data = new DispositionItemProductData(value);
  }
}
