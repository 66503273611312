import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FeedInputService } from '@app/@feed-inputs';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';
import { SelectData } from '@pso-ui/select/select.interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rim-type-select',
  templateUrl: './rim-type-select.component.html',
  styleUrls: ['./rim-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RimTypeSelectComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() readonly: boolean;
  @Input() withNoOption = false;
  data$: Observable<SelectData<string>[]>;

  constructor(
    private feedInputService: FeedInputService,
    private titleCasePipe: TitleCasePipe,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getOptions();
  }

  private getOptions() {
    this.data$ = this.feedInputService.getList('rim_types').pipe(
      pluck('data'),
      map((data) => data.map((d) => ({ value: d.id, label: this.titleCasePipe.transform(d.display_name) }))),
      map((d) => {
        if (this.withNoOption) {
          return [...d, { value: 'no', label: this.translateService.instant('rim-type.no') }];
        } else {
          return d;
        }
      })
    );
  }
}
