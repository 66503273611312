import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-expected-date-picker',
  templateUrl: './expected-date-picker.component.html',
  styleUrls: ['./expected-date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpectedDatePickerComponent {
  @Input() control: FormControl;
  DateTime = DateTime;
  pseudoControl = new FormControl();
  min = DateTime.now()
    .minus({
      days: 1,
    })
    .toJSDate();
}
