import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Roles } from '@app/@core/Roles';
import { Id } from '@app/@core/http/crud-model';
import { ROUTES } from '@app/@core/routes';
import { ProfileState } from '@app/@data/profile/profile-state';
import { VehicleResource } from '@app/@data/vehicles/vehicles.model';
import { RolePrefixService } from '@app/@shared/services/role-prefix.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-vehicle-registration-number-and-vin',
  templateUrl: './vehicle-registration-number-and-vin.component.html',
  styleUrls: ['./vehicle-registration-number-and-vin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleRegistrationNumberAndVinComponent {
  @Input() vehicle: VehicleResource;
  @Input() hideVin = false;
  ROUTES = ROUTES;
  Roles = Roles;
  constructor(protected rolePrefixService: RolePrefixService, protected store: Store, private router: Router) {}

  isPso(): boolean {
    return Roles.hasPsoRole(this.store.selectSnapshot(ProfileState.roles));
  }

  navigateToLink(id: Id): void {
    const url = this.rolePrefixService.transform(ROUTES.FLEET_VEHICLES_EDIT.path.replace('ID', id as string));
    if (this.isPso()) {
      window.open(url, '_blank');
    }
  }
}
