import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivitiesResource, ActivityTypeSubject } from '@app/@data/activities/activities.model';
import { ActivitiesService } from '@app/@data/activities/activities.service';
import { PagedResource } from '@app/@shared/interfaces/paged-resource';
import { prepareGetRequestParams } from '@app/@shared/utils/prepare-get-request-params';
import { Observable } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-vehicle-history-tab',
  templateUrl: './vehicle-history-tab.component.html',
  styleUrls: ['./vehicle-history-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleHistoryTabComponent implements OnInit {
  timeline$: Observable<PagedResource<ActivitiesResource>>;

  constructor(protected activitiesService: ActivitiesService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.timeline$ = this.getData();
  }

  getData(): Observable<PagedResource<ActivitiesResource>> {
    return this.activatedRoute.paramMap.pipe(
      pluck('params', 'id'),
      map((id: string) => Number(id)),
      switchMap((id: number) => {
        const params = {
          subject_type: ActivityTypeSubject.SUBJECT_VEHICLE,
          subject_id: id,
          paginate: 0,
          exclude_action_types: ['vehicle_showed'],
        };
        const p = prepareGetRequestParams({ ...params, sort: 'id.desc' });
        return this.activitiesService.get(p);
      })
    );
  }
}
