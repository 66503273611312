import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompletionType, CompletionTypeSlug } from '@data/tyres/tyres.model';

@Component({
  selector: 'app-completion-type',
  templateUrl: './completion-type.component.html',
  styleUrls: ['./completion-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletionTypeComponent {
  @Input() data: CompletionTypeSlug;
  protected readonly CompletionType = CompletionType;
}
