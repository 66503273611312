import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type Status = 'active' | 'inactive';

@Component({
  selector: 'app-active-or-inactive-status-icon',
  templateUrl: './active-or-inactive-status-icon.component.html',
  styleUrls: ['./active-or-inactive-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveOrInactiveStatusIconComponent {
  @Input() status: Status;
  activeOrInactive = ActiveOrInactive;
}

export class ActiveOrInactive {
  public static ACTIVE: Status = 'active';
  public static INACTIVE: Status = 'inactive';
}
