import { Component, Input } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'wchfs-records-info',
  templateUrl: './records-info.component.html',
  styleUrls: ['./records-info.component.scss'],
})
export class RecordsInfoComponent {
  @Input() totalElements: number;
  @Input() selection?: SelectionModel<any> = null;

  public clearSelection(): void {
    if (this.selection === null) {
      return;
    }

    this.selection.clear();
  }
}
