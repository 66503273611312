<h2>{{ 'vehicle-history' | translate }}</h2>
<div class="timeline" *ngIf="timeline$ | async as timeline; else loader">
  <div class="timeline" *ngIf="timeline.data.length; else empty">
    <div class="event" *ngFor="let event of timeline.data">
      <div class="event-content">
        <ng-container>
          <ng-container>
            <app-badge
              [text]="event?.action | uppercase"
              [backgroundColor]="'var(--ui-g4-gray4)'"
              [color]="'var(--primary-w-white)'"
            ></app-badge>
            <div class="timeline-circle" [style.borderColor]="'var(--ui-g4-gray4)'"></div>
          </ng-container>
        </ng-container>
      </div>
      <div class="id-n-date">
        {{ 'id-upper' | translate }} {{ event?.id }}
        <span class="date">{{ event?.created_at | date: 'dd.MM.yyyy HH:mm' }}</span>
      </div>
      <div class="desc">{{ event?.event_details }}</div>
      <app-vehicle-history-tab-properties [properties]="event?.properties"></app-vehicle-history-tab-properties>
    </div>
  </div>
</div>
<ng-template #loader>
  <app-dot-loader size="s"></app-dot-loader>
</ng-template>

<ng-template #empty>
  <app-empty-state>
    {{ 'no-vehicle-history' | translate }}
  </app-empty-state>
</ng-template>
