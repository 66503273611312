import { ExpandedListHeaderComponent } from './../expanded-list-header/expanded-list-header.component';
import { AfterContentInit, Component, ContentChild, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';

@Component({
  selector: 'wchfs-expanded-list-group',
  templateUrl: './expanded-list-group.component.html',
  styleUrls: ['./expanded-list-group.component.scss'],
})
export class ExpandedListGroupComponent implements OnDestroy, AfterContentInit {
  private hintElementListener: () => void;
  @Input() isOpnen = false;
  constructor(private readonly renderer: Renderer2) {}

  @ContentChild(ExpandedListHeaderComponent, { read: ElementRef }) header: ElementRef;

  ngAfterContentInit(): void {
    this.hintElementListener = this.renderer.listen(this.header.nativeElement, 'click', () => {
      this.isOpnen = !this.isOpnen;
    });
  }

  ngOnDestroy(): void {
    this.hintElementListener();
  }
}
