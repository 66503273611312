import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RimTypeValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';

@Component({
  selector: 'app-rim-type',
  templateUrl: './rim-type.component.html',
  styleUrls: ['./rim-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RimTypeComponent {
  @Input() type: RimTypeValue;
  translateIndex = 'rim-type.';
}
