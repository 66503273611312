import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DispositionSelectBaseComponent } from '../disposition-select.base.component';

@Component({
  selector: 'app-disposition-multi-select',
  templateUrl: './disposition-multi-select.component.html',
  styleUrls: ['./disposition-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispositionMultiSelectComponent extends DispositionSelectBaseComponent {}
