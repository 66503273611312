import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaResource } from '@data/media/media.model';

@Component({
  selector: 'app-print-or-empty-print',
  templateUrl: './print-or-empty-print.component.html',
  styleUrls: ['./print-or-empty-print.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintOrEmptyPrintComponent {
  @Input() medias: MediaResource[];
}
