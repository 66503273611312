<ng-container [ngSwitch]="completeWheel">
  <ng-container *ngSwitchCase="CompleteWheel.YES_ALU">
    <p>{{ 'yes' | translate | uppercase }}</p>
    <p>{{ 'alu' | translate }}</p>
  </ng-container>
  <ng-container *ngSwitchCase="CompleteWheel.YES_STEEL">
    <p>{{ 'yes' | translate | uppercase }}</p>
    <p>{{ 'steel' | translate }}</p>
  </ng-container>
  <ng-container *ngSwitchCase="CompleteWheel.NO">
    <p>{{ 'no' | translate | uppercase }}</p>
    <p></p>
  </ng-container>
</ng-container>
