<wchfs-per-page
  (perPage)="setPerPage.emit($event)"
  [meta]="listData?.response?.meta"
  *ngIf="listData?.response"
></wchfs-per-page>
<div>
  <wchfs-new-paginator
    (page$)="setPage.emit($event)"
    [inputField]="true"
    [meta]="listData?.response?.meta"
    *ngIf="listData?.response"
  ></wchfs-new-paginator>
</div>
