<div
  class="select"
  #select
  (click)="onInputClick()"
  [class]="{
    'select--focused': (focus$ | async),
    'select--disabled': disabled
  }"
>
  <span class="select__display-value" *ngIf="displayValue; else placeholderBlock">{{ displayValue }}</span>
  <ng-template #placeholderBlock>
    <span class="select__placeholder">{{ placeholder }}</span>
  </ng-template>
  <div class="select__suffix">
    <wchfs-badge *ngIf="selectionModel.selected.length > 1" color="grey-5" textColor="grey-2"
      >+{{ selectionModel.selected.length - 1 }}</wchfs-badge
    >
    <wchfs-icon
      (click)="onXIconClick(); $event.stopPropagation()"
      [svgIcon]="'pso-legacy:cross_ico_l'"
      *ngIf="!selectionModel.isEmpty()"
    ></wchfs-icon>
    <wchfs-icon (click)="onArrowIconClick()" [svgIcon]="icon$ | async"></wchfs-icon>
  </div>
</div>

<ng-template #options>
  <div class="select-dropdown">
    <div class="select-dropdown__search" *ngIf="config.search">
      <wchfs-search-input
        [formControl]="search"
        [debounce]="600"
        [placeholder]="'search' | translate"
      ></wchfs-search-input>
    </div>
    <div class="select-dropdown__chips">
      <wchfs-chip-list>
        <wchfs-chip
          *ngFor="let item of selectionModel.selected"
          [removable]="true"
          (remove)="onItemRemove($event, item)"
        >
          {{ item.getLabel() }}
        </wchfs-chip>
      </wchfs-chip-list>
    </div>

    <div class="select-dropdown__items">
      <div class="select-dropdown__item" (click)="onSelectAllClick()">
        <!--        <div class="select-dropdown__checkbox">-->
        <!--          <wchfs-checkbox (click)="$event.preventDefault()"></wchfs-checkbox>-->
        <!--        </div>-->
        <div class="select-dropdown__label">{{ 'select-all' | translate }}</div>
      </div>

      <ng-container *ngFor="let item of filteredItems$ | async; let i = index">
        <div
          class="select-dropdown__item"
          (click)="onItemClick(item)"
          *ngIf="showModeClicked || (this.config.showMoreIdx ? config.showMoreIdx && i < config.showMoreIdx : true)"
        >
          <div class="select-dropdown__checkbox">
            <wchfs-checkbox
              (click)="$event.preventDefault()"
              [checked]="selectionModel.isSelected(item)"
            ></wchfs-checkbox>
          </div>
          <div class="select-dropdown__label">{{ item.getLabel() }}</div>
        </div>

        <div
          class="select-dropdown__separator"
          *ngIf="this.config.showMoreIdx ? i === config.showMoreIdx - 1 : false"
        ></div>

        <div
          class="select-dropdown__item"
          wchfsPadding="0 0 0 2"
          *ngIf="!showModeClicked && this.config.showMoreIdx ? i === config.showMoreIdx : false"
          (click)="showModeClicked = true"
        >
          <div class="select-dropdown__label">{{ this.config.showMoreLabel | translate }}</div>
        </div>
      </ng-container>

      <div class="select-dropdown__empty" *ngIf="emptyFilteringResults$ | async">{{ 'no-entries' | translate }}</div>
    </div>
  </div>
</ng-template>
