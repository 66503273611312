<button
  class="main-button"
  [attr.color]="'primary'"
  [disabled]="disabled"
  [textColor]="textColor"
  [size]="size"
  wchfs-flat-button
>
  {{ text }}
  <wchfs-icon [fill]="textColor" size="x-small" svgIcon="wchfs:arrow-form-down"></wchfs-icon>
</button>
<ng-template #extraButtons> <ng-content></ng-content></ng-template>
