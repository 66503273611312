<h1 class="total-records-info" fxLayoutAlign="center center">
  Znaleziono rekordów: <span class="bold">&nbsp; {{ totalElements }}</span>
  <span *ngIf="selection !== null && selection.selected.length > 0">
    , wybrano <span class="bold">{{ selection.selected.length }}</span> &nbsp;(<span
      (click)="clearSelection()"
      class="clear-selection"
      >wyczyść</span
    >)
  </span>
</h1>
