<div fxLayout="row" fxLayoutAlign="start center">
  <button
    wchfs-flat-button
    (click)="sort.emit(name)"
    class="table-sorting-header"
    [style.padding]="0"
    [style.margin]="0"
    [style.text-align]="'left'"
    [style.line-height.px]="16"
    [class]="sorting.has(name) && sorting.get(name) !== null ? 'active' : 'inactive'"
  >
    <ng-content></ng-content>
    <wchfs-icon
      *ngIf="true"
      [fill]="sorting.has(name) && sorting.get(name) !== null ? 'primary' : 'grey-4'"
      [class.rotated]="sorting.has(name) && sorting.get(name) === 'asc'"
      [style.margin]="0"
      class="icon"
      fxLayoutAlign="center center"
      size="24"
      svgIcon="pso-legacy:triangle-arrow-down"
    ></wchfs-icon>
  </button>
</div>
