import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VehicleTyreDefaultResource, VehicleTyreDefaults } from '@data/vehicles/vehicles.model';

@Component({
  selector: 'app-vehicle-tyre-defaults-segment',
  templateUrl: './vehicle-tyre-defaults-segment.component.html',
  styleUrls: ['./vehicle-tyre-defaults-segment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleTyreDefaultsSegmentComponent {
  segment: string;
  @Input() set tyreDefaults(value: VehicleTyreDefaultResource[]) {
    this.segment = new VehicleTyreDefaults(value).getSegment();
  }
}
