import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Id, Read, Update } from '@app/@core/http/crud-model';
import { DataResource } from '@app/@shared/interfaces/data-resource';
import { PagedResource } from '@app/@shared/interfaces/paged-resource';
import { Observable } from 'rxjs';
import { InvoiceDraftResource, InvoicePatchRequest, InvoiceResource, InvoiceStatusSlug } from './invoice.model';
import { InvoicesPanelFilterParams } from './invoices-panel/invoices-panel.model';
import { ReadById } from '@core/http/crud-model/read-by-id';
import { ExternalInvoicesService } from '../external-invoice/external-invoices.service';
import { NoteService } from '@data/note/note.service';

const DEFAULT_WITH = 'fleet,parent,child,author,dispositions,invoiceSetting,notes';
@Injectable({
  providedIn: 'root',
})
export class InvoicesBaseService
  implements
    Read<Partial<InvoicesPanelFilterParams>, InvoiceResource>,
    ReadById<InvoiceResource>,
    Update<InvoicePatchRequest, InvoiceResource> {
  readonly ENDPOINT = '/invoices';

  constructor(protected httpClient: HttpClient) {}

  get(params: Partial<InvoicesPanelFilterParams>): Observable<PagedResource<InvoiceResource>> {
    return this.httpClient.get<PagedResource<InvoiceResource>>(`${this.ENDPOINT}`, {
      params: new HttpParams({ fromObject: { ...params, with: params.with ?? DEFAULT_WITH } }),
    });
  }

  getById(id: Id): Observable<DataResource<InvoiceResource>> {
    return this.httpClient.get<DataResource<InvoiceResource>>(`${this.ENDPOINT}/${id}`, {
      params: new HttpParams({
        fromObject: {
          with: DEFAULT_WITH,
        },
      }),
    });
  }

  getByIdWithoutRelations(id: Id): Observable<DataResource<InvoiceResource>> {
    return this.httpClient.get<DataResource<InvoiceResource>>(`${this.ENDPOINT}/${id}`);
  }

  update(id: Id, params: InvoicePatchRequest): Observable<DataResource<InvoiceResource>> {
    return this.httpClient.patch<DataResource<InvoiceResource>>(`${this.ENDPOINT}/${id}`, params);
  }

  getDrafts(params: Partial<InvoicesPanelFilterParams>): Observable<PagedResource<InvoiceDraftResource>> {
    return this.httpClient.get<PagedResource<InvoiceDraftResource>>(`${this.ENDPOINT}/draft`, {
      params,
    });
  }

  updateStatus(id: Id, status: InvoiceStatusSlug): Observable<DataResource<InvoiceResource>> {
    return this.httpClient.patch<DataResource<InvoiceResource>>(`${this.ENDPOINT}/${id}`, {
      status: status,
    });
  }

  invoicesExternalFactory(): ExternalInvoicesService {
    return new ExternalInvoicesService(this.httpClient, this.ENDPOINT);
  }

  noteFactory(id: Id): NoteService {
    return new NoteService(this.httpClient, this.ENDPOINT, id);
  }
}
