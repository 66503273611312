export class VehicleSearchInputComponentSizeFactory {
  static getSize(size: VehicleSearchInputComponentSizeValue): VehicleSearchInputComponentSize {
    switch (size) {
      case 's':
        return new SSize();
      case 'xl':
        return new XlSize();
      default:
        return null;
    }
  }
}

export interface VehicleSearchInputComponentSize {
  getHeight(): number;
  getFontSize(): number;
  getWidth(): string;
}

class XlSize implements VehicleSearchInputComponentSize {
  getHeight(): number {
    return 47;
  }

  getFontSize(): number {
    return 20;
  }

  getWidth(): string {
    return '462px';
  }
}

class SSize implements VehicleSearchInputComponentSize {
  getHeight(): number {
    return 36;
  }

  getFontSize(): number {
    return 13;
  }

  getWidth(): string {
    return null;
  }
}

export type VehicleSearchInputComponentSizeValue = 's' | 'xl';
