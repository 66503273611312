<div class="registration-number" *ngIf="vehicle?.registration_number">
  <div
    class="copy-to-clipboard"
    appCopyToClipboard
    [copyText]="vehicle?.registration_number"
    [wchfsTooltip]="'copy-registration-number' | translate"
    (click)="$event.stopPropagation()"
  >
    <wchfs-icon svgIcon="pso:copy_m" size="24"></wchfs-icon>
  </div>
  <div
    class="plate-number bold-text"
    (click)="navigateToLink(vehicle?.id); $event.stopPropagation()"
    [class.hoverable]="isPso()"
  >
    {{ vehicle?.registration_number }}
  </div>
</div>
<div class="light-text mt" *ngIf="!hideVin">{{ vehicle?.vin }}</div>
