import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tread-depth-input',
  templateUrl: './tread-depth-input.component.html',
  styleUrls: ['./tread-depth-input.component.scss'],
})
export class TreadDepthInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() max: number;
  @Input() control: AbstractControl;
  @Input() treadValue: string;
  @Output() updateTreadControl = new EventEmitter<string>();
  internalControl: AbstractControl;
  private subscription: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.createControl();
    this.listenValueChanges();
    this.updateTreadControl.emit(this.treadValue);
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  check(event: KeyboardEvent, value: string): void | undefined {
    // TODO: Implement focus out functionality
    // if (['Enter', 'Tab'].includes(event.key)) {
    //   event.preventDefault();
    //   // this.inputGroupFocusService.focusNext(this.index); // TODO: focus next
    //   this.focusOut.emit();
    //
    //   return;
    // }

    const commaCount = value.split('.').length - 1;
    const semicolonCount = value.split(',').length - 1;

    if ([',', '.'].includes(event.key) && commaCount + semicolonCount === 1) {
      event.preventDefault();
    }

    if (event.key === '.' && (value.length === 0 || value.length > 2)) {
      // INFO: only one '.', prevent '.' on start
      event.preventDefault();
    }

    if (event.key === ',' && (value.length === 0 || value.length > 2)) {
      // INFO: only one ',', prevent ',' on start
      event.preventDefault();
    }

    if (
      !['ArrowLeft', 'ArrowUp', 'ArrowDown', 'ArrowRight', 'Backspace', 'Delete', 'Tab', 'Enter'].includes(event.key) &&
      value.length > 3
    ) {
      // INFO: max 4 chars
      event.preventDefault();
    }

    if (
      ![
        'ArrowLeft',
        'ArrowUp',
        'ArrowDown',
        'ArrowRight',
        'Backspace',
        'Delete',
        'Tab',
        'Enter',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.',
        ',',
      ].includes(event.key)
    ) {
      // INFO: only numbers and action keys
      event.preventDefault();
    }

    this.changeDetectorRef.markForCheck();
  }

  private listenValueChanges() {
    this.subscription = this.internalControl.valueChanges.subscribe((value: string) => {
      let _value = value.replace(',', '.');
      _value = this.parseValue(_value);
      // const depth = parseFloat(_value);
      this.internalControl.setValue(_value, { emitEvent: false, onlySelf: true });
      // this.store.dispatch(new SetTyreTread(this.tyre, depth));
      this.control.setValue(_value);
      this.changeDetectorRef.markForCheck();
    });
  }

  private createControl() {
    this.internalControl = new UntypedFormControl(this.treadValue ? this.treadValue : this.control.value, {
      updateOn: 'blur',
    });
    this.internalControl.addValidators(this.control.validator);
    this.changeDetectorRef.markForCheck();
  }

  private parseValue(value: string): string {
    if (value.toString().endsWith('.')) {
      value += '0';
      return value;
    }

    if (!value.toString().includes('.')) {
      value += '.0';
      return value;
    }

    return value;
  }
}
