import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appScrollTarget]',
})
export class ScrollTargetDirective {
  constructor(private elementRef: ElementRef) {}

  scroll(): void {
    this.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
