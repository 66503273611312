import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TyreType } from '@app/@data/tyres/tyres.model';
import { VehicleTyreDefaultResource } from '@app/@data/vehicles/vehicles.model';

@Component({
  selector: 'app-tyre-type-icon',
  templateUrl: './tyre-type-icon.component.html',
  styleUrls: ['./tyre-type-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TyreTypeIconComponent {
  @Input() tyreDefaults: VehicleTyreDefaultResource[];
  TyreType = TyreType;
}
