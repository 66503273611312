import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

type Key = '=1' | '=2' | '=3' | '=4' | 'other';

@Component({
  selector: 'app-textarea-with-limitation',
  templateUrl: './textarea-with-limitation.component.html',
  styleUrls: ['./textarea-with-limitation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaWithLimitationComponent {
  @Input() label = '';
  @Input() placeholder = 'write-comment';
  @Input() control: FormControl;
  @Input() textareaMaxLength = 230;
  @Input() height = 75;

  messageMapping: Record<Key, string> = {
    '=1': 'characters.singular',
    '=2': 'characters.plural',
    '=3': 'characters.plural',
    '=4': 'characters.plural',
    other: 'characters.plural2',
  };
}
