import { Component, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'wchfs-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss'],
})
export class InfoTooltipComponent {
  opened = false;

  constructor(public elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside && this.opened) {
      this.opened = false;
    }
  }

  openTooltip() {
    this.opened = !this.opened;
  }
}
