import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActiveTireInteractiveSvgComponent } from '@pso-ui/tyre-active-interactive-svg/active-tire-interactive-svg.component';

@Component({
  selector: 'app-four-wheels-active-tire-interactive-svg',
  templateUrl: './four-wheels-active-tire-interactive-svg.component.html',
  styleUrls: ['./four-wheels-active-tire-interactive-svg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FourWheelsActiveTireInteractiveSvgComponent extends ActiveTireInteractiveSvgComponent {}
