<div class="delete-modal" fxFill fxLayout="column" fxLayoutAlign="start start">
  <div fxFill fxLayout="row" fxLayoutAlign="space-between center">
    <div div fxLayout="column" fxLayoutAlign="space-around center">
      <h2 class="text-supporting-3 text-h2">{{ data.title }}</h2>
    </div>
    <div class="btn" (click)="closeDialog()">
      <wchfs-icon color="secondary" svgIcon="wchfs:cross"></wchfs-icon>
    </div>
    <!-- <wchfs-icon color="secondary" svgIcon="wchfs:cross"></wchfs-icon> -->
  </div>
  <hr class="hr text-grey-5" />
  <div class="input-box" fxLayout="column" fxLayoutAlign="center center"></div>
  <div fxFill fxLayout="row" fxLayoutAlign="end end">
    <button wchfs-button (click)="closeDialog()" class="text-secondary">ANULUJ</button>
    <button pso-button (click)="confirmDelete()" color="supporting-5-light-blue">POTWIERDŹ</button>
  </div>
</div>
