import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wchfs-expanded-list-header',
  templateUrl: './expanded-list-header.component.html',
  styleUrls: ['./expanded-list-header.component.scss'],
})
export class ExpandedListHeaderComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
