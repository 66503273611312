import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VehicleTypeSelectBaseComponent } from '@app/@feed-inputs/vehicles/vehicle-type-select.base.component';

@Component({
  selector: 'app-vehicle-type-single-select',
  templateUrl: './vehicle-type-single-select.component.html',
  styleUrls: ['./vehicle-type-single-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleTypeSingleSelectComponent extends VehicleTypeSelectBaseComponent {
  @Input() width: number;
  @Input() readonly: boolean;
}
