import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-tread-depth',
  templateUrl: './tread-depth.component.html',
  styleUrls: ['./tread-depth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreadDepthComponent {
  @Input() depth: string;
}
