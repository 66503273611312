import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AddressResource } from '@app/@data/address/address.model';

@Component({
  selector: 'app-address-with-coordinates',
  templateUrl: './address-with-coordinates.component.html',
  styleUrls: ['./address-with-coordinates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressWithCoordinatesComponent {
  @Input() address: AddressResource;
  @Input() lat: string;
  @Input() lng: string;
}
