import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RollingResistance } from '@data/tyres/tyres.model';

@Component({
  selector: 'app-tyre-fuel-efficient',
  templateUrl: './tyre-fuel-efficient.component.html',
  styleUrls: ['./tyre-fuel-efficient.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TyreFuelEfficientComponent {
  @Input() rollingResistance: RollingResistance;
}
