import { Directive, ElementRef } from '@angular/core';
import { environment } from '@env/environment';

@Directive({
  selector: '[appTodo]',
})
export class TodoDirective {
  constructor(elRef: ElementRef) {
    if (!environment.production) {
      elRef.nativeElement.style.background = 'lightcoral';
    }
  }
}
