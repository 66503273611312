<div>
  <table>
    <tr *ngFor="let ap of activityProperties">
      <td class="key">
        <strong>{{ ap.key }}</strong>
      </td>
      <td class="old">
        <s>{{ ap.old || '-' }}</s>
      </td>
      <td class="current">{{ ap.current || '-' }}</td>
    </tr>
  </table>
</div>
