import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  VehicleSearchInputComponentSize,
  VehicleSearchInputComponentSizeFactory,
  VehicleSearchInputComponentSizeValue,
} from '@pso-ui/vehicle-search-input/vehicle-search-input.model';

@Component({
  selector: 'app-vehicle-search-input',
  templateUrl: './vehicle-search-input.component.html',
  styleUrls: ['./vehicle-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleSearchInputComponent {
  @Output() confirm = new EventEmitter<void>();
  get size(): VehicleSearchInputComponentSizeValue {
    return this._size;
  }
  @Input()
  set size(value: VehicleSearchInputComponentSizeValue) {
    this._size = value;
    this.vehicleSearchInputComponentSize = VehicleSearchInputComponentSizeFactory.getSize(value);
  }
  @Input() control: FormControl;
  @Input() min: number;
  private _size: VehicleSearchInputComponentSizeValue = 'xl';

  vehicleSearchInputComponentSize: VehicleSearchInputComponentSize;
}
