import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VehicleTyreDefaultResource } from '@data/vehicles/vehicles.model';

@Component({
  selector: 'app-vehicle-tyre-defaults-type',
  templateUrl: './vehicle-tyre-defaults-type.component.html',
  styleUrls: ['./vehicle-tyre-defaults-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleTyreDefaultsTypeComponent {
  @Input() tyreDefaults: VehicleTyreDefaultResource[];
}
