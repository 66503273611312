import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { InputOptionResource } from '@app/@feed-inputs';
import { TypeaheadValueProp } from '@pso-ui/typeahead/pso-typeahead.model';
import { Subscription } from 'rxjs';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsoTypeaheadBaseComponent {
  @Input() control: AbstractControl;
  @Input() valueProp: TypeaheadValueProp;
  @Input() readonly = false;
  searchControl = new UntypedFormControl('');
  search = '';
  private subscription: Subscription;

  @HostBinding('style')
  get disabledStyles() {
    return this.control.disabled
      ? {
          opacity: 0.5,
        }
      : '';
  }

  @HostBinding('style.pointerEvents') get pointerEvents() {
    return this.readonly || this.control.disabled ? 'none' : undefined;
  }

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.clearInput();
    this.patchSearchControl();
  }

  handleResultSelected(result: InputOptionResource): void {
    if (result === null) {
      this.search = '';
      this.control.reset();
      this.changeDetectorRef.markForCheck();
    } else {
      this.search = result?.display_name || '';

      this.control.patchValue(this.valueProp ? result?.[this.valueProp] : result || '');
      this.control.markAsTouched();
      this.changeDetectorRef.markForCheck();
    }
  }

  clearInput(): void {
    this.subscription = this.control.valueChanges.subscribe((res) => {
      if (!res) {
        this.searchControl.patchValue(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private patchSearchControl() {
    if (this.control.value || this.control.value?.display_name) {
      this.searchControl.patchValue(this.control.value?.display_name ?? this.control.value);
    }
  }
}
