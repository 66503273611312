import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wchfs-search-form-more',
  templateUrl: './search-form-more.component.html',
  styleUrls: ['./search-form-more.component.scss'],
})
export class SearchFormMoreComponent {
  @Input() isOpen = false;
  @Input() showButton = false;
  @Input() labels = {
    open: 'Wyszukiwanie zaawansowane',
    hide: 'Schować',
    search: 'Search Advanced',
  };
  @Output() search = new EventEmitter();

  // TODO: form or formState (touched) etc

  submitOnClick() {
    this.search.emit(true);
  }
}
