import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InvoicesBaseService } from '@app/@data/invoices/invoices-base.service';
import { InvoicesPanelFilterParams } from '@app/@data/invoices/invoices-panel/invoices-panel.model';
import { PsoFilter } from '@data/filtering/filtering.interface';
import { SelectData } from '@pso-ui/select/select.interfaces';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Id } from '@core/http/crud-model';

@Component({
  template: '',
})
export class InvoiceSelectBaseComponent implements PsoFilter<SelectData<unknown>[]>, OnInit {
  @Input() control: FormControl;
  @Input() label = 'invoices';
  @Input() excludedIds: Id[] = [];
  @Input() set params(value: Partial<InvoicesPanelFilterParams>) {
    this._params = { ...this._params, ...value };

    if (!this.control?.disabled) {
      this.data$ = this.getData(this.params);
    } else {
      this.data$ = of([]);
    }
  }

  get params(): Partial<InvoicesPanelFilterParams> {
    return this._params;
  }

  data$: Observable<SelectData<unknown>[]>;

  private _params: Partial<InvoicesPanelFilterParams> = { paginate: '0' };

  constructor(private invoiceBaseService: InvoicesBaseService) {}

  ngOnInit(): void {
    if (!this.control?.disabled) {
      this.data$ = this.getData(this.params);
    } else {
      this.data$ = of([]);
    }
  }

  getData(params?: Partial<InvoicesPanelFilterParams>): Observable<SelectData<unknown>[]> {
    return this.invoiceBaseService.get(params).pipe(
      map((data) => {
        return data.data
          .filter((d) => !this.excludedIds.includes(d.id))
          .map((d) => ({ value: d.id, label: `${d.number} (${d.id})` }));
      })
    );
  }
}
