import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectorRef,
  ContentChild,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  INITIAL_PADDING_VALUE_TO_ADD,
  PADDING_LEFT_FOR_HINT,
  PADDING_RIGHT_SPACE_WITH_HINT,
} from '../../../form/form-field/form-field.constants';

@Directive({
  selector: `wchfs-form-field[wchfsHintData]`,
  exportAs: 'wchfsHintInput',
})
export class WchfsInputHintDirective implements AfterViewInit, OnDestroy {
  @Input() autocompleteData: string[];
  @Input() isFilterByMail = false;
  @ContentChild(NgControl, { static: true }) input: any;
  private autocompleteHintElement: HTMLElement;
  private hintElementListener: any;
  private displayedDomain = '';

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private focusMonitor: FocusMonitor
  ) {}

  @HostListener('input', ['$event']) inputChange(event: Event) {
    const targetElement = event.target as HTMLInputElement;
    this.setAutocompleteText(targetElement.value);
    this.adjustPadding();
  }

  ngAfterViewInit() {
    this.focusMonitor.monitor(this.elementRef.nativeElement, true).subscribe((origin) => {
      if (!!origin && !this.autocompleteHintElement) {
        this.createHint();
        this.renderer.addClass(this.autocompleteHintElement, 'input-with-hint');
      } else {
        this.clearHint();
      }
      this.adjustPadding();
    });
  }

  setAutocompleteText(value: string) {
    let text = '';

    if (value.length) {
      if (this.isFilterByMail) {
        text = this.filterMailDomain(value);
      } else {
        text = this.autocompleteData.find((autocompleteElement: string) => autocompleteElement.includes(value));
      }
    }

    this.renderer.setProperty(this.autocompleteHintElement.children[0], 'textContent', text);
  }

  filterMailDomain(value: string): string {
    let hintDomainText = '';
    const indexOfStartedDomainMail = value.indexOf('@');
    if (indexOfStartedDomainMail !== -1) {
      const domainText = value.substring(indexOfStartedDomainMail + 1, value.length);
      const filteredDomain = this.autocompleteData.find((autocompleteElement: string) =>
        autocompleteElement.includes(domainText)
      );
      hintDomainText = !!filteredDomain ? filteredDomain.replace(domainText, '') : '';
    }
    this.displayedDomain = hintDomainText;
    return hintDomainText;
  }

  clearHint() {
    this.renderer.setProperty(this.autocompleteHintElement.children[0], 'textContent', '');
  }

  createHint() {
    const autocompleteContainer = this.renderer.createElement('span');
    this.renderer.addClass(autocompleteContainer, 'hint-on-focus');

    const wrapperToAttach = this.elementRef.nativeElement.querySelector('.wchfs-mask-end');
    this.renderer.appendChild(wrapperToAttach, autocompleteContainer);

    this.changeDetectorRef.detectChanges();
    this.autocompleteHintElement = wrapperToAttach;

    this.hintElementListener = this.renderer.listen(this.autocompleteHintElement.parentElement, 'click', (event) => {
      if (event.target.contains(this.autocompleteHintElement)) {
        this.input.control.setValue(this.input.control.value + this.displayedDomain);
      }
    });
  }

  adjustPadding() {
    const inputElement = this.elementRef.nativeElement.querySelector('.wchfs-form-field-infix input');

    const hintWidth = this.autocompleteHintElement.clientWidth
      ? this.autocompleteHintElement.clientWidth
      : INITIAL_PADDING_VALUE_TO_ADD;

    this.renderer.setStyle(inputElement, 'padding-right', `${hintWidth + PADDING_RIGHT_SPACE_WITH_HINT}px`);
    this.renderer.setStyle(inputElement, 'padding-left', `${PADDING_LEFT_FOR_HINT}px`);
  }

  ngOnDestroy() {
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
    this.hintElementListener();
  }
}
