<wchfs-form-field class="file" [class.fileover]="fileOver">
  <input
    (change)="uploadFile($event)"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    [accept]="accept"
    placeholder="File"
    type="file"
    wchfsInput
    [formControl]="fileControl"
  />

  <div class="content" draggable="true" [class.file-input-disabled]="fileControl?.disabled">
    <ng-content></ng-content>
  </div>
</wchfs-form-field>
